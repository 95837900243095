import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { AddNotificationForBlocksDTO } from '../dto/AddNotificationForBlocks.dto';
import { useNotificationService } from '../service/Notification.service';
import { IUseAddBlocksNotification } from './types';

export const useAddBlocksNotification = (): IUseAddBlocksNotification => {
  const { addBlocksNotification } = useNotificationService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleNotifyBlocks = useCallback(
    async (data: AddNotificationForBlocksDTO) => {
      setLoading(true);
      setError(null);
      setSuccessCreation(false);

      await handleErrors(
        async () => {
          await addBlocksNotification(data);
          setSuccessCreation(true);
        },
        async (err) => {
          console.error(
            'Error creando la notificación para las agrupaciones:',
            err,
          );
          setError(
            'Hubo un error al crear la notificación para las agrupaciones',
          );
          setSuccessCreation(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [addBlocksNotification, handleErrors],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleNotifyBlocks, loading, successCreation, error];
};
