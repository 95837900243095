import { useCallback, useState } from 'react';

import { LocalStorageVault } from '../../../../utils/LocalStorageVault';
import { encryptData } from '../../../../utils/encryption';
import { useHandleErrors } from '../../../../utils/errors';

import { useAuthContext } from '../../../authentication/core/context/Authentication.context';
import { AuthResponseDTO } from '../../../authentication/core/dto/AuthResponse.dto';
import { User } from '../../../authentication/core/entity/User.entity';
import { TopbarService } from '../service/Topbar.service';

const NeighborhoodChange = () => {
  const { changeNeighborhood } = TopbarService();
  const { authState, setAuthState } = useAuthContext();
  const storage = new LocalStorageVault();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);

  const [neighborhoods, setNeighborhoods] = useState(authState?.roles);

  const neighborhoobChange = useCallback((): boolean => {
    const nActive = authState?.roles.find((role) => role.active);

    return (
      neighborhoods?.find(
        (rol) => rol.active && rol.neighborhood_id != nActive?.neighborhood_id,
      )?.active || false
    );
  }, [authState?.roles, neighborhoods]);

  const setNeighborhoodSelected = (id: number) => {
    if (neighborhoods) {
      const updated = neighborhoods.map((nn) => {
        return {
          ...nn,
          active: nn.neighborhood_id === id,
        };
      });

      setNeighborhoods(updated);
    }
  };

  const handleChangeNeighborhood = async () => {
    setLoading(true);
    const neighborhoodId = neighborhoods!.find(
      (n) => n.active,
    )!.neighborhood_id;

    await handleErrors(
      async () => {
        const response = await changeNeighborhood(neighborhoodId);

        const authData: Pick<
          AuthResponseDTO,
          'refresh_token' | 'access_token'
        > = response.data;

        authState!.roles = authState!.roles.map((rol) => {
          return {
            ...rol,
            active: rol.neighborhood_id === neighborhoodId ? true : false,
          };
        });

        const authInfo: User = {
          ...authState!,
          refresh_token: authData.refresh_token,
          access_token: authData.access_token,
        };

        const dataEcrypted = encryptData(JSON.stringify(authInfo));

        if (dataEcrypted) {
          await storage.resetStorage('auth');
          await storage.saveValueInStorage('auth', dataEcrypted);
        }

        setAuthState(authInfo);

        window.location.reload();
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  return {
    loading,
    handleChangeNeighborhood,
    neighborhoods,
    setNeighborhoodSelected,
    neighborhoobChange,
  };
};

export { NeighborhoodChange };
