import React, { useState } from 'react';

import { Button } from '../../../ui/atoms/Button';
import { Loading } from '../../../ui/atoms/Loading';
import Text from '../../../ui/atoms/Text';

import { Modal } from '../../../ui/molecules/Modal';
import { NeighbourhoodCard } from '../../../ui/molecules/NeighborhoodCard';
import { Popup } from '../../../ui/molecules/Popup';

import { UserType } from '../../../authentication/core/enums/UserType.enum';
import { NeighborhoodChange } from '../../core/hooks/NeighboorhoodChange';
import styles from './styles.module.scss';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NeighborhoodChangeModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const {
    loading,
    neighborhoods,
    setNeighborhoodSelected,
    handleChangeNeighborhood,
    neighborhoobChange,
  } = NeighborhoodChange();

  const [completedChange, setCompletedChange] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        showCloseIcon={false}
        styleOverride={styles.container}
      >
        <div className={styles.header}>
          <Text
            text={'Cambiar conjunto residencial'}
            textStyle={'SH1-Bold'}
            color='primary'
          />
        </div>
        <div className={styles.spacing} />
        <div className={styles.content}>
          {neighborhoods &&
            neighborhoods
              .filter((rol) => rol.user_type === UserType.ADMIN)
              .map((rol) => {
                return (
                  <NeighbourhoodCard
                    onClickCard={() =>
                      setNeighborhoodSelected(rol.neighborhood_id)
                    }
                    key={rol.neighborhood_id}
                    rol={rol}
                    isSelected={rol.active}
                  />
                );
              })}
        </div>
        <div className={styles.buttons}>
          {loading ? (
            <Loading backgroundColor={true} />
          ) : (
            <>
              <Button
                text='Cerrar'
                onPress={() => setIsModalOpen(false)}
                popup
                closeButton
              />
              <Button
                disabledButton={!neighborhoobChange()}
                text='Cambiar conjunto'
                onPress={handleChangeNeighborhood}
                isLoading={loading}
                changeButton
                style={styles.button}
                popup
              />
            </>
          )}
        </div>
        <div className={styles.spacing} />
      </Modal>
      <Popup
        isOpen={completedChange}
        onClose={() => setCompletedChange(false)}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Contraseña cambiada exitosamente!'
        buttons={false}
      />
    </>
  );
};

export { NeighborhoodChangeModal };
