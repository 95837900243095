import React from 'react';

import { Image } from '../../../feature/ui/atoms/Image';
import Text from '../../../feature/ui/atoms/Text';

import { LoginForm } from '../../../feature/authentication/LoginForm';
import styles from './styles.module.scss';

const LoginTemplate: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Image srcName='loginLogo' width='30vh' height='auto' />
        <Text
          text='Bienvenido a ResiRed'
          textStyle='H2-Bold'
          color='secondary2'
        />
        <Text
          text='Por favor, ingresa tus credenciales.'
          textStyle='B1-Regular'
          color='tertiary3'
        />
        <LoginForm />
      </div>
      <div className={styles.image}>
        <Image srcName='loginImage' width='460px' height='auto' />
      </div>
    </div>
  );
};

export { LoginTemplate };
