import React from 'react';

import Text from '../../../ui/atoms/Text';

import { Attachment } from '../../../ui/molecules/Attachment';

import styles from './styles.module.scss';
import { INewsDetailsProps } from './types';

const NewsDetails: React.FC<INewsDetailsProps> = ({ news }) => {
  return (
    <div className={styles.newsDetails}>
      <div className={styles.newsTextHeader}>
        <div className={styles.text}>
          <Text text={news.category} textStyle='B3-Regular' color='primary' />
          <Text
            text={news.title}
            textStyle='SH2-Bold'
            color='primary'
            className={styles.title}
          />
          <div className={styles.description}>
            <Text text={news.description} textStyle='B2-Regular' />
          </div>
        </div>
      </div>
      {news.image && (
        <Attachment label='Foto de portada adjunta' url={news.image} />
      )}

      {news.details && (
        <Attachment label='Archivos adjuntos' url={news.details} />
      )}
    </div>
  );
};

export { NewsDetails };
