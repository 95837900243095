import { BellNotification, SendDiagonal } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import { Button } from '../../../ui/atoms/Button';
import Dropdown from '../../../ui/atoms/Dropdown';
import { Icon } from '../../../ui/atoms/Icon';
import { Input } from '../../../ui/atoms/Input';
import Text from '../../../ui/atoms/Text';

import { Popup } from '../../../ui/molecules/Popup';

import CategoryDropdown from '../../../ui/organisms/CategoryDropdown';
import { FilterComponent } from '../../../ui/organisms/Filter';

import { BlockEntity } from '../../../neighborhood/core/entity/Block.entity';
import { GroupingType } from '../../../neighborhood/core/enums/GroupingType.enum';
import { HomeNameEntity } from '../../../residents/core/entity/HomeName.entity';
import { AddCategoryNotificationDTO } from '../../core/dto/AddCategoryNotification.dto';
import { AddNotificationForBlocksDTO } from '../../core/dto/AddNotificationForBlocks.dto';
import { AddNotificationForNeighborhoodDTO } from '../../core/dto/AddNotificationForNeighborhood.dto';
import { AddNotificationForResidencesDTO } from '../../core/dto/AddNotificationForResidences.dto';
import { CategoryNotificationEntity } from '../../core/entity/CategoryNotification.entity';
import { NotificationPriority } from '../../core/enums/NotificationPriority.enum';
import { useAddCategoryNotification } from '../../core/hooks/AddCategory.hook';
import { useAddBlocksNotification } from '../../core/hooks/AddNotificationForBlocks.hook';
import { useAddNeighborhoodNotification } from '../../core/hooks/AddNotificationForNeighborhood.hook';
import { useAddResidencesNotification } from '../../core/hooks/AddNotificationForResidences.dto';
import styles from './styles.module.scss';

interface NotificationFormProps {
  isLoading?: boolean;
  categories?: CategoryNotificationEntity[] | null;
  fetchCategories: () => void;
  blocksList?: BlockEntity[] | null;
  residencesList?: HomeNameEntity[] | null;
  groupingType: GroupingType;
}

const NotificationForm: React.FC<NotificationFormProps> = ({
  categories,
  fetchCategories,
  blocksList,
  residencesList,
  groupingType,
}) => {
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    category: '',
    priority: '',
    blocks_id: [] as string[],
    homes_id: [] as string[],
  });
  const [errors, setErrors] = useState({
    title: '',
    message: '',
    priority: '',
  });
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [, setGeneralError] = useState<string | null>(null);
  const [notifyNeighborhood, NotifyLoading, notifySuccess, notifyError] =
    useAddNeighborhoodNotification();
  const [notifyBlock, NotifyLoadingB, notifySuccessB, notifyErrorB] =
    useAddBlocksNotification();
  const [notifyResidency, NotifyLoadingR, notifySuccessR, notifyErrorR] =
    useAddResidencesNotification();
  const [addCategory, addCategoryLoading, addCategorySuccess] =
    useAddCategoryNotification();
  const [singularOrPluralGroup, setSingularOrPluralGroup] =
    useState<string>('');
  const [singularOrPluralResidency, setSingularOrPluralResidency] =
    useState<string>('');

  useEffect(() => {
    const hasFilters = Object.keys(selectedFilters).some(
      (key) => selectedFilters[key].length > 0,
    );
    setIsButtonDisabled(!hasFilters);
  }, [selectedFilters]);

  useEffect(() => {
    const checkLoading = () => {
      return selectedFilters['todos']
        ? NotifyLoading
        : selectedFilters['torre'] && selectedFilters['apartamento']
          ? NotifyLoadingB || NotifyLoadingR
          : selectedFilters['torre']
            ? NotifyLoadingB
            : selectedFilters['apartamento']
              ? NotifyLoadingR
              : false;
    };

    setIsLoading(checkLoading());
  }, [NotifyLoading, NotifyLoadingB, NotifyLoadingR, selectedFilters]);

  useEffect(() => {
    const shouldShowPopup =
      (selectedFilters['apartamento'] &&
        selectedFilters['torre'] &&
        notifySuccessB &&
        notifySuccessR) ||
      (selectedFilters['apartamento'] &&
        !selectedFilters['torre'] &&
        notifySuccessR) ||
      (selectedFilters['torre'] &&
        !selectedFilters['apartamento'] &&
        notifySuccessB) ||
      notifySuccess;

    setIsSuccessPopupOpen(shouldShowPopup);
  }, [notifySuccess, notifySuccessB, notifySuccessR, selectedFilters]);

  useEffect(() => {
    const errorsExist = notifyError || notifyErrorB || notifyErrorR;
    setGeneralError(
      errorsExist ? notifyError || notifyErrorB || notifyErrorR : null,
    );
  }, [notifyError, notifyErrorB, notifyErrorR]);

  useEffect(() => {
    setIsSuccessPopupOpen(isSuccessPopupOpen);
    if (isSuccessPopupOpen) {
      setFormData({
        title: '',
        message: '',
        category: '',
        priority: '',
        blocks_id: [],
        homes_id: [],
      });
    }
  }, [isSuccessPopupOpen]);

  const handleChange = (field: string, value: string) => {
    setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  };

  const handleCreateNotification = async (
    data:
      | AddNotificationForNeighborhoodDTO
      | AddNotificationForBlocksDTO
      | AddNotificationForResidencesDTO,
  ) => {
    selectedFilters['apartamento'] &&
      (await notifyResidency(data as AddNotificationForResidencesDTO));
    selectedFilters['torre'] &&
      (await notifyBlock(data as AddNotificationForBlocksDTO));
    selectedFilters['todos'] &&
      (await notifyNeighborhood(data as AddNotificationForNeighborhoodDTO));
  };

  const handleCreateNotificationSubmit = async () => {
    const newErrors = {
      title: formData.title ? '' : 'Este campo es requerido',
      message: formData.message ? '' : 'Este campo es requerido',
      priority: formData.priority ? '' : 'Este campo es requerido',
    };

    setErrors(newErrors);

    if (
      !formData.title ||
      !formData.message ||
      !formData.category ||
      !formData.priority
    ) {
      return;
    }

    const data = {
      title: formData.title,
      message: formData.message,
      priority: formData.priority,
      category_id: parseInt(formData.category),
      blocks_id: formData.blocks_id,
      homes_id: formData.homes_id,
    };

    selectedFilters['todos'] &&
      (await handleCreateNotification(
        data as AddNotificationForNeighborhoodDTO,
      ));
    selectedFilters['torre'] &&
      (await handleCreateNotification(data as AddNotificationForBlocksDTO));
    selectedFilters['apartamento'] &&
      (await handleCreateNotification(data as AddNotificationForResidencesDTO));

    const groupNames: { [key: string]: string[] } = {
      [GroupingType.INTERIOR]: ['el interior', 'los interiores'],
      [GroupingType.BLOQUE]: ['el bloque', 'los bloques'],
      [GroupingType.UNIDAD]: ['la unidad', 'las unidades'],
      [GroupingType.TORRE]: ['la torre', 'las torres'],
    };

    const singularOrPlural = formData.blocks_id.length > 1 ? 1 : 0;
    setSingularOrPluralGroup(
      groupNames[groupingType ?? '']?.[singularOrPlural] || '',
    );

    setSingularOrPluralResidency(
      formData.homes_id.length > 1 ? 'las residencias' : 'la residencia',
    );
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    window.location.reload();
  };

  const handleSelectedCategory = (categoryValue: string) => {
    const selectedCategory = categories?.find(
      (category) => category.id === parseInt(categoryValue),
    );
    selectedCategory &&
      setFormData((prevFormData) => ({
        ...prevFormData,
        title: selectedCategory.default_title,
        message: selectedCategory.default_message,
        priority: selectedCategory.priority,
        category: categoryValue,
      }));
  };

  const handleAddCategory = async (newCategory: AddCategoryNotificationDTO) => {
    await addCategory(newCategory);
    fetchCategories();
  };

  const handleFilterChange = (filters: { [key: string]: string[] }) => {
    setSelectedFilters(filters);

    filters['torre'] &&
      setFormData((prevFormData) => ({
        ...prevFormData,
        blocks_id: filters['torre'],
      }));

    filters['apartamento'] &&
      setFormData((prevFormData) => ({
        ...prevFormData,
        homes_id: filters['apartamento'],
      }));
  };

  const getSuccessPopupTitle = () => {
    return notifySuccess
      ? '¡Notificación publicada correctamente para el conjunto!'
      : notifySuccessR && !notifySuccessB
        ? `¡Notificación publicada correctamente para ${singularOrPluralResidency}!`
        : notifySuccessB && !notifySuccessR
          ? `¡Notificación publicada correctamente para ${singularOrPluralGroup}!`
          : notifySuccessB && notifySuccessR
            ? `¡Notificación publicada correctamente para ${singularOrPluralGroup} y ${singularOrPluralResidency}!`
            : '';
  };

  const categoryOptions =
    categories?.map((category) => ({
      label: category.name,
      value: category.id.toString(),
    })) || [];

  const blocksOptions =
    blocksList?.map((block) => ({
      label: block.name,
      value: block.id.toString(),
    })) || [];

  const residencesOptions =
    residencesList?.map((residence) => ({
      label: residence.name,
      value: residence.id.toString(),
    })) || [];

  return (
    <div className={styles.addCard}>
      <div className={styles.title}>
        <Icon color='primary'>
          <BellNotification height={18} width={18} />
        </Icon>
        <div className={styles.titleText}>
          <Text
            text={'Nueva notificación'}
            textStyle={'SH2-Bold'}
            color='primary'
          />
        </div>
      </div>
      <div className={styles.formContainer}>
        <Text text='Destinatarios:' textStyle='B2-Medium' />
        <div className={styles.filterComponent}>
          <FilterComponent
            blocksList={blocksOptions}
            groupingType={groupingType}
            residencesList={residencesOptions}
            onFiltersChange={handleFilterChange}
          />
        </div>
        <CategoryDropdown
          categories={categoryOptions}
          onSelectedCategory={handleSelectedCategory}
          onAddCategory={handleAddCategory}
          addIsLoading={addCategoryLoading}
          successCreation={addCategorySuccess}
        />
        <Dropdown
          label='Prioridad *'
          options={[
            { label: 'Alta', value: NotificationPriority.HIGH },
            { label: 'Media', value: NotificationPriority.MEDIUM },
            { label: 'Baja', value: NotificationPriority.LOW },
          ]}
          selectedValue={formData.priority}
          onValueChange={(value) => handleChange('priority', value)}
          error={errors.priority}
          styleContainer={styles.input}
        />
        <Input
          label='Título *'
          placeholder='Ingresa el título...'
          value={formData.title}
          onChangeText={(value) => handleChange('title', value)}
          maxLength={50}
          modernStyle
          style={styles.input}
          error={errors.title}
        />
        <div className={styles.body}>
          <Text text='Mensaje *' textStyle='B2-Medium' color='black' />
          <textarea
            value={formData.message}
            onChange={(e) => handleChange('message', e.target.value)}
            placeholder='Ingresa tu mensaje...'
            className={
              errors.message === ''
                ? styles.textarea
                : styles.textarea + ' ' + styles.errorBorder
            }
            maxLength={2500}
          />
          {errors.message && (
            <Text
              text={errors.message}
              textStyle={'B2-Medium'}
              color='primary'
            />
          )}
        </div>
        <Button
          text='Enviar notificación'
          textStyle='B2-Medium'
          onPress={handleCreateNotificationSubmit}
          icon={
            <Icon color='neutral' height={18} width={18}>
              <SendDiagonal />
            </Icon>
          }
          cancelButton={!isButtonDisabled}
          disabledButton={isButtonDisabled}
          isLoading={isLoading}
          style={styles.button}
        />
      </div>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiAnounce'}
        width={250}
        titleText={getSuccessPopupTitle()}
        buttons={false}
      />
    </div>
  );
};

export { NotificationForm };
