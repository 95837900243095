import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { AddNotificationForNeighborhoodDTO } from '../dto/AddNotificationForNeighborhood.dto';
import { useNotificationService } from '../service/Notification.service';
import { IUseAddNeighborhoodNotification } from './types';

export const useAddNeighborhoodNotification =
  (): IUseAddNeighborhoodNotification => {
    const { addNeighborhoodNotification } = useNotificationService();
    const [handleErrors] = useHandleErrors();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successCreation, setSuccessCreation] = useState(false);

    const handleNotifyNeighborhood = useCallback(
      async (data: AddNotificationForNeighborhoodDTO) => {
        setLoading(true);
        setError(null);
        setSuccessCreation(false);

        await handleErrors(
          async () => {
            await addNeighborhoodNotification(data);
            setSuccessCreation(true);
          },
          async (err) => {
            console.error(
              'Error creando la notificación para el conjunto:',
              err,
            );
            setError('Hubo un error al crear la notificación para el conjunto');
            setSuccessCreation(false);
          },
          () => {
            setLoading(false);
          },
        );
      },
      [addNeighborhoodNotification, handleErrors],
    );

    useEffect(() => {
      if (error) {
        toast.error(error);
        setError(null);
      }
    }, [error]);

    return [handleNotifyNeighborhood, loading, successCreation, error];
  };
