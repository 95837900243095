import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { PasswordService } from '../service/Password.service';
import { IUseResetPassword } from './types';

export const ResetPassword = (): IUseResetPassword => {
  const { resetPswd } = PasswordService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleResetPassword = useCallback(
    async (token: string, email: string) => {
      setSuccess(false);
      setLoading(true);
      setError(null);
      try {
        await resetPswd(token, email);
        setSuccess(true);
      } catch (err) {
        console.error('Error restaurando la clave:', err);
        setError(
          'Hubo un error restaurando la clave. Por favor, intente nuevamente.',
        );
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    },
    [resetPswd],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleResetPassword, loading, success, error];
};
