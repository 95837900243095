import React from 'react';

import { Image } from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { IListEmptyProps } from './types';

const ListEmpty: React.FC<IListEmptyProps> = ({
  width = 200.6,
  imageSrc = 'resiSad',
  height = 214.3,
  title = 'No se encontraron resultados',
  message = '',
  popup = false,
  header = null,
}) => {
  return (
    <div className={styles.container}>
      {header && (
        <Text
          text={header}
          textStyle={popup ? 'B1-Bold' : 'SH2-Bold'}
          color='black'
        />
      )}
      <Image srcName={imageSrc} width={width} height={height} />
      <Text text={title} textStyle={popup ? 'B1-Bold' : 'B1-Bold'} />
      {message && (
        <Text text={message} textStyle={popup ? 'B1-Regular' : 'B2-Regular'} />
      )}
    </div>
  );
};

export { ListEmpty };
