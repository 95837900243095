import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from '../../../feature/ui/organisms/Sidebar';

const PrivateLayout: React.FC = () => {
  return (
    <div>
      <Sidebar />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default PrivateLayout;
