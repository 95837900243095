import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { CreateResidentEntity } from '../entity/CreateResident.entity';
import { useResidentService } from '../service/Residents.service';
import { IUseCreateResident } from './types';

export const useCreateResident = (): IUseCreateResident => {
  const { createResident } = useResidentService();
  const [handleErrors] = useHandleErrors();
  const [loadingCreateResident, setLoadingCreateResident] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleCreateResident = useCallback(
    async (data: CreateResidentEntity) => {
      setLoadingCreateResident(true);
      setError(null);
      setSuccessCreation(false);

      await handleErrors(
        async () => {
          await createResident(data);
          setSuccessCreation(true);
        },
        async (err) => {
          console.error('Error creando el residente:', err);
          setError('Hubo un error al crear el residente');
          setSuccessCreation(false);
        },
        () => {
          setLoadingCreateResident(false);
        },
      );
    },
    [createResident, handleErrors],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleCreateResident, loadingCreateResident, successCreation, error];
};
