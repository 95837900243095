const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (!BACKEND_URL) {
  throw new Error('REACT_APP_BACKEND_URL is not defined');
}

const getEnvValue = (
  envValue: string,
  type: 'string' | 'number',
): string | number | undefined => {
  let value;

  if (type === 'number' && !isNaN(Number(envValue))) {
    value = Number(envValue);
  } else if (type === 'string' && typeof envValue === 'string') {
    value = envValue;
  } else {
    throw new Error(`${envValue} must be a string or a number`);
  }

  return value;
};

interface TEnvVariables {
  BACKEND_URL: string;
}

export const ENV_VARIABLES: TEnvVariables = {
  BACKEND_URL: getEnvValue(BACKEND_URL, 'string') as string,
};
