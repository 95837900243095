import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { PqrsEntity } from '../entity/Pqrs.entity';
import { PqrsState } from '../enums/PqrsState.enum';
import { usePqrsService } from '../service/Pqrs.service';
import { IUseGetPqrs } from './types';

export const usePqrs = (): IUseGetPqrs => {
  const { getPqrs } = usePqrsService();
  const [handleErrors] = useHandleErrors();
  const [pqrs, setPqrs] = useState<PqrsEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPqrs = useCallback(
    async (state: PqrsState) => {
      setLoading(true);
      setError(null);

      await handleErrors(
        async () => {
          const response = await getPqrs(state);
          setPqrs(response.data);
        },
        async (err) => {
          console.error('Error obteniendo las PQRS:', err);
          setError('Hubo un error al obtener las PQRS');
          setPqrs([]);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [getPqrs, handleErrors],
  );

  return [fetchPqrs, pqrs, loading, error];
};
