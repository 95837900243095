import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { CategoryNotificationEntity } from '../entity/CategoryNotification.entity';
import { useNotificationService } from '../service/Notification.service';
import { IUseGetCategoryNotifications } from './types';

export const useCategoryNotifications = (): IUseGetCategoryNotifications => {
  const { getNotificationsCategory } = useNotificationService();
  const [handleErrors] = useHandleErrors();
  const [categories, setCategories] = useState<
    CategoryNotificationEntity[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getNotificationsCategory();
        setCategories(response.data);
      },
      async (err) => {
        console.error('Error obteniendo las categorias:', err);
        setError('Hubo un error al obtener las categorias');
        setCategories([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [getNotificationsCategory, handleErrors]);

  return [fetchCategories, categories, loading, error];
};
