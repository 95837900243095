import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { ResidentEntity } from '../entity/Resident.entity';
import { useResidentService } from '../service/Residents.service';

export const useResidents = (
  active: boolean,
): [ResidentEntity[] | null, boolean, () => void, string | null] => {
  const { getResidents } = useResidentService();
  const [handleErrors] = useHandleErrors();
  const [residents, setResidents] = useState<ResidentEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchResidents = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getResidents(active);
        setResidents(response.data);
      },
      async (err) => {
        console.error('Error fetching residents:', err);
        setError('Hubo un error al obtener los residentes');
      },
      () => {
        setLoading(false);
      },
    );
  }, [handleErrors, getResidents, active]);

  useEffect(() => {
    fetchResidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [residents, loading, fetchResidents, error];
};
