import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { CreateNewsEntity } from '../entity/CreateNews.entity';
import { NewsUseCase } from '../usecase/News.usecase';

export const useNewsService = (): NewsUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getNews = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get(`/admin/neighborhood/news`);
  }, [axiosInstance]);

  const createNews = useCallback(
    async (data: CreateNewsEntity): Promise<AxiosResponse> => {
      const form = new FormData();

      form.append('title', data.title);
      form.append('content', data.content);
      form.append('category', data.category);
      if (data.image) {
        form.append('image', data.image.item(0)!);
      }

      if (data.details) {
        form.append('details', data.details.item(0)!);
      }

      return await axiosInstance().post('/admin/v2/neighborhood/news', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [axiosInstance],
  );

  return {
    getNews,
    createNews,
  };
};
