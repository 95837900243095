import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { CreateResidentEntity } from '../entity/CreateResident.entity';
import { ResidentUseCase } from '../usecase/Residents.usecase';

export const useResidentService = (): ResidentUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getResidents = useCallback(
    async (active: boolean): Promise<AxiosResponse> => {
      return await axiosInstance().get(
        `/admin/neighborhood/residents?active=${active}`,
      );
    },
    [axiosInstance],
  );

  const removeResident = useCallback(
    async (id: number): Promise<AxiosResponse> => {
      return await axiosInstance().delete(`/admin/residents/${id}`);
    },
    [axiosInstance],
  );

  const createResident = useCallback(
    async (data: CreateResidentEntity): Promise<AxiosResponse> => {
      return await axiosInstance().post('/admin/resident', data);
    },
    [axiosInstance],
  );

  const getResidentsRequests = useCallback(
    async (filter: string): Promise<AxiosResponse> => {
      return await axiosInstance().get(
        `/admin/request/residents?decision=${filter}`,
      );
    },
    [axiosInstance],
  );

  const rejectResidentsRequests = useCallback(
    async (id: number): Promise<AxiosResponse> => {
      return await axiosInstance().post(`/admin/request/${id}/rejection`);
    },
    [axiosInstance],
  );

  const acceptResidentsRequests = useCallback(
    async (requestId: number, homeId: number): Promise<AxiosResponse> => {
      return await axiosInstance().post(
        `/admin/home/${homeId}/request/${requestId}/accept`,
      );
    },
    [axiosInstance],
  );

  const getHomesNames = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get('/neighborhood/homes');
  }, [axiosInstance]);

  return {
    getResidents,
    removeResident,
    createResident,
    getResidentsRequests,
    rejectResidentsRequests,
    acceptResidentsRequests,
    getHomesNames,
  };
};
