import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { useNeighborhoodService } from '../service/Neighborhood.service';
import { IUseVacateHome } from './types';

export const useVacateHome = (): IUseVacateHome => {
  const { vacateHome } = useNeighborhoodService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleVacate = useCallback(
    async (homeId: number) => {
      setLoading(true);
      setError(null);
      await handleErrors(
        async () => {
          await vacateHome(homeId);
          window.location.reload();
        },
        async (err) => {
          console.error('Error desocupando el apartamento/casa:', err);
          setError('Hubo un error al desocupar el apartamento/casa');
        },
        () => {
          setLoading(false);
        },
      );
    },
    [handleErrors, vacateHome],
  );

  return [handleVacate, loading, error];
};
