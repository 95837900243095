import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { useGuardsService } from '../service/Guards.service';

export const useReactivateGuard = (): [
  (id: number) => Promise<void>,
  boolean,
  string | null,
] => {
  const { reactivateGuard } = useGuardsService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleReactivateGuard = useCallback(
    async (id: number) => {
      setLoading(true);
      setError(null);
      await handleErrors(
        async () => {
          await reactivateGuard(id);
        },
        async (err) => {
          console.error('Error reactivando el guarda:', err);
          setError('Hubo un error al reactivar el guarda');
        },
        () => {
          setLoading(false);
        },
      );
    },
    [handleErrors, reactivateGuard],
  );

  return [handleReactivateGuard, loading, error];
};
