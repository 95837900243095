import { Xmark } from 'iconoir-react';

import React from 'react';
import Modal from 'react-modal';

import { Icon } from '../../atoms/Icon';
import { Loading } from '../../atoms/Loading';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { IModalProps } from './types';

Modal.setAppElement('#root');

const ModalComponent: React.FC<IModalProps> = ({
  isOpen,
  children,
  onRequestClose,
  title,
  styleOverride,
  contentStyleOverride,
  showCloseIcon = true,
  isLoading = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`${styles.modal} ${styleOverride}`}
      overlayClassName={styles.overlay}
    >
      {title || showCloseIcon ? (
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            {title && (
              <div className={styles.title}>
                <Text text={title} textStyle={'SH2-Bold'} color='primary' />
              </div>
            )}
            {showCloseIcon && !isLoading && (
              <button onClick={onRequestClose} className={styles.closeButton}>
                <Icon color='primary'>
                  <Xmark />
                </Icon>
              </button>
            )}
          </div>
        </div>
      ) : null}

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Loading size='medium' backgroundColor={true} />
        </div>
      ) : (
        <div className={contentStyleOverride}>{children}</div>
      )}
    </Modal>
  );
};

export { ModalComponent as Modal };
