import { useCallback, useEffect, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { PackageEntity } from '../entity/Package.entity';
import { PackageStatus } from '../enums/PackageStatus.enum';
import { usePackageReportService } from '../service/PackageReport.service';
import { IUseReportPackage } from './types';

export const useReportPackage = (status: PackageStatus): IUseReportPackage => {
  const { getPackageReport } = usePackageReportService();
  const [handleErrors] = useHandleErrors();
  const [reports, setReports] = useState<PackageEntity[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchReports = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getPackageReport(status);
        setReports(response.data.data);
      },
      async (err) => {
        console.error('Error obteniendo el reporte de paquetes:', err);
        setError('Hubo un error al obtener el reporte de paquetes');
        setReports([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [getPackageReport, handleErrors, status]);

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return [reports, loading, fetchReports, error];
};
