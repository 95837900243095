import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { ChatBubbleWarning, Edit } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import { Button } from '../../ui/atoms/Button';
import { Icon } from '../../ui/atoms/Icon';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';
import Text from '../../ui/atoms/Text';

import { ListEmpty } from '../../ui/molecules/ListEmpty';

import { GroupingType } from '../../neighborhood/core/enums/GroupingType.enum';
import { useGetBlocks } from '../../neighborhood/core/hooks/GetBlocks.hook';
import { useGetHomesNames } from '../../residents/core/hooks/GetHomesNames.hook';
import { NotificationEntity } from '../core/entity/Notification.entity';
import { useCategoryNotifications } from '../core/hooks/GetCategoryNotifications.hook';
import { useNotifications } from '../core/hooks/GetNotifications.hook';
import { NotificationDetails } from './NotificationDetails';
import { NotificationForm } from './NotificationForm';
import styles from './styles.module.scss';

const NotificationList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [error] = useState(null);
  const [filteredNotifications, setFilteredNotifications] = useState<
    NotificationEntity[]
  >([]);
  const [selectedNotification, setSelectedNotification] =
    useState<NotificationEntity | null>(null);
  const [isCreatingNotification, setIsCreatingNotification] = useState(false);
  const [fetchCategories, categories] = useCategoryNotifications();
  const [fetchNotifications, notifications, loading] = useNotifications();
  const [fetchBlocks, blocksList, groupingType] = useGetBlocks();
  const [fetchHomeNames, residencesList] = useGetHomesNames();

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notifications) {
      setFilteredNotifications(
        notifications.filter((item) =>
          item.title.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }
  }, [searchTerm, notifications]);

  const handleNotificationClick = (notification: NotificationEntity) => {
    setSelectedNotification(notification);
    setIsCreatingNotification(false);
  };

  const handleCreateNotification = async () => {
    setSelectedNotification(null);
    setIsCreatingNotification(true);
    fetchCategories();
    fetchBlocks();
    fetchHomeNames();
  };

  return (
    <div
      className={`${styles.container} ${isCreatingNotification ? styles.createMode : ''}`}
    >
      <div className={styles.leftSection}>
        <div className={styles.topContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder='Buscar por título'
              value={searchTerm}
              onChangeText={setSearchTerm}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              text='Nueva notificación'
              style={styles.newNotificationButton}
              icon={
                <Icon width={15} height={15}>
                  <Edit />
                </Icon>
              }
              cancelButton
              onPress={handleCreateNotification}
            />
          </div>
        </div>
        <div className={styles.notificationList}>
          {loading ? (
            <Loading size='small' backgroundColor={true} />
          ) : error ? (
            <div>Error: {error}</div>
          ) : filteredNotifications.length > 0 ? (
            <ul className={styles.notificationList}>
              {filteredNotifications.map((notification) => (
                <li
                  key={notification.id}
                  className={styles.notificationItem}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className={styles.notificationCard}>
                    <div className={styles.notificationCardContent}>
                      <Text text={notification.title} textStyle='B1-Medium' />
                      <Text
                        text={notification.message}
                        textStyle='B1-Regular'
                        className={styles.truncate}
                      />
                    </div>
                    <div className={styles.notificationCardInfo}>
                      <Icon color='primary' height={13} width={13}>
                        <ChatBubbleWarning />
                      </Icon>
                      <Text
                        text={format(parseISO(notification.date), 'dd-MMMM', {
                          locale: es,
                        })}
                        textStyle='B2-Medium'
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <ListEmpty />
          )}
        </div>
      </div>
      <div className={styles.rightSection}>
        {isCreatingNotification ? (
          <NotificationForm
            categories={categories}
            fetchCategories={fetchCategories}
            blocksList={blocksList}
            residencesList={residencesList}
            groupingType={groupingType as GroupingType}
          />
        ) : selectedNotification ? (
          <NotificationDetails notification={selectedNotification} />
        ) : (
          <div className={styles.noNotificationSelected}>
            <ListEmpty
              header={'Seleccione un elemento para leerlo'}
              imageSrc='resiTip'
              width={208.14}
              height={178.035}
              title={'No hay nada seleccionado'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { NotificationList };
