import React, { useEffect, useState } from 'react';

import { LinkOrButton } from '../../ui/atoms/LinkOrButton';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';

import { ListEmpty } from '../../ui/molecules/ListEmpty';

import Table from '../../ui/organisms/Table';

import { PackageEntity } from '../core/entity/Package.entity';
import { PackageStatus } from '../core/enums/PackageStatus.enum';
import { useReportPackage } from '../core/hooks/GetPackageReport.hook';
import styles from './styles.module.scss';

export const PackageReportTable: React.FC = () => {
  const [status, setStatus] = useState(PackageStatus.TO_COLLECT);
  const [reports, loading] = useReportPackage(status);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReports, setFilteredReports] = useState<PackageEntity[]>([]);

  useEffect(() => {
    if (reports) {
      setFilteredReports(
        reports.filter(
          (report) =>
            report.block.toLowerCase().includes(searchTerm.toLowerCase()) ||
            report.home_number
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report.receiver.toLowerCase().includes(searchTerm.toLowerCase()) ||
            report.package_transporter
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report.tracking_number
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report.created_date
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            (report.deliver_date
              ? report.deliver_date
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              : '') ||
            report.received_guard
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            (report.delivered_guard
              ? report.delivered_guard
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              : ''),
        ),
      );
    }
  }, [searchTerm, reports]);

  const columns = [
    { title: 'Destino', sortable: true, type: 'string' as const },
    { title: 'Destinatario', sortable: true, type: 'string' as const },
    { title: 'Transportadora', sortable: true, type: 'string' as const },
    { title: '#Guía', sortable: true, type: 'string' as const },
    { title: 'Fecha recepción', sortable: true, type: 'date' as const },
    ...(status === PackageStatus.DELIVERED
      ? [{ title: 'Fecha entrega', sortable: true, type: 'date' as const }]
      : []),
    ...(status === PackageStatus.DELIVERED
      ? [{ title: 'Guardia', sortable: true, type: 'string' as const }] //TODO update title depending on the state
      : [{ title: 'Guardia', sortable: true, type: 'string' as const }]),
  ];

  const titleCase = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  const data = filteredReports.map((report) => [
    { text: `${report.block}, ${report.home_number}` },
    { text: titleCase(report.receiver) },
    { text: titleCase(report.package_transporter) },
    { text: report.tracking_number },
    { text: new Date(report.created_date).toLocaleString() },
    ...(status === PackageStatus.DELIVERED
      ? [
          {
            text: report.deliver_date
              ? new Date(report.deliver_date).toLocaleString()
              : '',
          },
        ]
      : []),
    ...(status === PackageStatus.DELIVERED
      ? [{ text: titleCase(report.delivered_guard || '') }]
      : [{ text: titleCase(report.received_guard) || '' }]),
  ]);

  return (
    <div className={styles.main}>
      <div className={styles.topContainer}>
        <div className={styles.filterContainer}>
          <LinkOrButton
            text='Por Recoger'
            textStyle='B1-Medium'
            onClick={() => setStatus(PackageStatus.TO_COLLECT)}
            className={status === PackageStatus.TO_COLLECT ? styles.active : ''}
            color={
              status === PackageStatus.TO_COLLECT ? 'primary' : 'secondary2'
            }
            filter={true}
            active={status === PackageStatus.TO_COLLECT}
          />
          <LinkOrButton
            text='Entregados'
            textStyle='B1-Medium'
            onClick={() => setStatus(PackageStatus.DELIVERED)}
            className={status === PackageStatus.DELIVERED ? styles.active : ''}
            color={
              status === PackageStatus.DELIVERED ? 'primary' : 'secondary2'
            }
            filter={true}
            active={status === PackageStatus.DELIVERED}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            placeholder='Buscar por destino, destinatario, transportadora, etc.'
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {loading ? (
          <Loading size='small' backgroundColor={true} />
        ) : reports ? (
          filteredReports.length > 0 ? (
            <Table imageSrc='package' columns={columns} data={data} />
          ) : (
            <ListEmpty width={150.6} height={164.3} />
          )
        ) : (
          <Loading size='small' backgroundColor={true} />
        )}
      </div>
    </div>
  );
};
