import { ArrowRightCircle, Building } from 'iconoir-react';

import React from 'react';

import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { INeighborhoodCardProps } from './types';

const NeighbourhoodCard = ({
  rol,
  onClickCard,
  isSelected = false,
}: INeighborhoodCardProps) => {
  return (
    <div
      className={`${styles.card} ${isSelected ? styles.selected : ''}`}
      onClick={isSelected ? undefined : onClickCard}
    >
      <div className={styles.icon}>
        <Icon color={'primary'} width={36} height={36}>
          <Building />
        </Icon>
      </div>
      <div className={styles.content}>
        <Text
          text={`Id: ${rol.neighborhood_id}`}
          textStyle={'B1-Bold'}
          color={'gray'}
        />
        <Text
          text={
            rol.neighborhood_name.length < 40
              ? rol.neighborhood_name
              : `${rol.neighborhood_name.substring(0, 40)}...`
          }
          textStyle={'SH2-Regular'}
        />
      </div>
      <Icon color={'primary'} width={32} height={32}>
        <ArrowRightCircle />
      </Icon>
    </div>
  );
};

export { NeighbourhoodCard };
