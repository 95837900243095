import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { RespondPqrsEntity } from '../entity/RespondPqrs.entity';
import { usePqrsService } from '../service/Pqrs.service';

export const useRespondPqrs = (): [
  (data: { ticket_number: string; response: string }) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { respondPqrs } = usePqrsService();
  const [handleErrors] = useHandleErrors();
  const [loadingRespondPqrs, setLoadingRespondPqrs] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successResponse, setSuccessResponse] = useState(false);

  const handleRespondPqrs = useCallback(
    async (data: RespondPqrsEntity) => {
      setLoadingRespondPqrs(true);
      setError(null);
      setSuccessResponse(false);

      await handleErrors(
        async () => {
          await respondPqrs(data);
          setSuccessResponse(true);
        },
        async (err) => {
          console.error('Error respondiendo a la PQRS:', err);
          setError('Hubo un error al responder a la PQRS');
          setSuccessResponse(false);
        },
        () => {
          setLoadingRespondPqrs(false);
        },
      );
    },
    [handleErrors, respondPqrs],
  );

  return [handleRespondPqrs, loadingRespondPqrs, successResponse, error];
};
