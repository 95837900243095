import React from 'react';

import styles from './styles.module.scss';
import { IToggleSwitchProps } from './types';

const ToggleSwitch = ({ text, checked, onChange }: IToggleSwitchProps) => {
  return (
    <label
      className={styles.switch}
      style={{
        fontFamily: 'Space Grotesk, sans-serif',
        fontSize: '13px',
        fontWeight: '400',
      }}
    >
      {text}
      <input type='checkbox' checked={checked} onChange={onChange} />
      <span className={styles.slider}></span>
    </label>
  );
};

export { ToggleSwitch };
