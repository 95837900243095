import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { AddHomeDTO } from '../dto/AddHome.dto';
import { useNeighborhoodService } from '../service/Neighborhood.service';
import { IUseCreateHome } from './types';

export const useCreateHome = (): IUseCreateHome => {
  const { createHome } = useNeighborhoodService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleCreateResidence = useCallback(
    async (data: AddHomeDTO) => {
      setLoading(true);
      setError(null);
      setSuccessCreation(false);

      await handleErrors(
        async () => {
          await createHome(data);
          setSuccessCreation(true);
        },
        async (err) => {
          console.error('Error creando la residencia:', err);
          setError('Hubo un error al crear la residencia');
          setSuccessCreation(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [createHome, handleErrors],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleCreateResidence, loading, successCreation, error];
};
