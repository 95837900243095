import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { AddCategoryNotificationDTO } from '../dto/AddCategoryNotification.dto';
import { useNotificationService } from '../service/Notification.service';
import { IUseAddCategoryNotification } from './types';

export const useAddCategoryNotification = (): IUseAddCategoryNotification => {
  const { addCategoryNotification } = useNotificationService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleAddCategory = useCallback(
    async (data: AddCategoryNotificationDTO) => {
      setLoading(true);
      setError(null);
      setSuccessCreation(false);

      await handleErrors(
        async () => {
          await addCategoryNotification(data);
          setSuccessCreation(true);
        },
        async (err) => {
          console.error('Error creando la categoría de notificación:', err);
          setError('Hubo un error al crear la categoría de notificación');
          setSuccessCreation(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [addCategoryNotification, handleErrors],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleAddCategory, loading, successCreation, error];
};
