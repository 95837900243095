import car from './car.png';
import finishAlert from './finish-alert.png';
import guardAvatar from './guard-avatar.png';
import guard from './guard.png';
import lock from './lock.svg';
import LoginImage from './login-img.png';
import LoginLogo from './login-logo.png';
import packageImg from './package.png';
import PageNotFound from './page-not-found.png';
import pqrs from './pqrs.png';
import resiAnounce from './resi-anounce.png';
import resiBags from './resi-bags.png';
import resiHappy from './resi-happy.png';
import resiLaptop from './resi-laptop.png';
import resiLook from './resi-look.png';
import resiSad from './resi-sad.png';
import resiSurprised from './resi-surprised.png';
import resiThumbUp from './resi-thumb-up.png';
import resiTip from './resi-tip.png';
import residentAvatar from './resident-avatar.png';
import resident from './resident.png';
import ResiredLogo from './resired-logo.png';
import unlock from './unlock.svg';
import visit from './visit.png';

const imgs = {
  loginLogo: LoginLogo,
  loginImage: LoginImage,
  resiredLogo: ResiredLogo,
  finishAlert: finishAlert,
  resiSad: resiSad,
  pageNotFound: PageNotFound,
  guard: guard,
  visit: visit,
  resiAnounce: resiAnounce,
  resident: resident,
  resiBags: resiBags,
  resiSurprised: resiSurprised,
  resiHappy: resiHappy,
  resiLook: resiLook,
  resiLaptop: resiLaptop,
  guardAvatar: guardAvatar,
  residentAvatar: residentAvatar,
  resiThumbUp: resiThumbUp,
  pqrs: pqrs,
  resiTip: resiTip,
  car: car,
  package: packageImg,
  lock: lock,
  unlock: unlock,
};

export type TImages = keyof typeof imgs;
export { imgs };
