import React from 'react';

import { NotificationList } from '../../../../feature/notifications/templates/NotificationList';

import styles from './styles.module.scss';

const NotificationsTemplate = () => {
  return (
    <div className={styles.content}>
      <NotificationList />
    </div>
  );
};

export default NotificationsTemplate;
