import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SidebarProvider } from './feature/ui/organisms/Sidebar/context/SidebarContext';

import { AppNavigation } from './utils/navigation';

import './config/env';
import { AuthProvider } from './feature/authentication/core/context/Authentication.context';

function App(): React.JSX.Element {
  return (
    <Router>
      <AuthProvider>
        <SidebarProvider>
          <AppNavigation />
        </SidebarProvider>
      </AuthProvider>
      <ToastContainer position='bottom-right' pauseOnHover />
    </Router>
  );
}

export default App;
