import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { usePqrsService } from '../service/Pqrs.service';
import { IUseTakePqrs } from './types';

export const useTakePqrs = (): IUseTakePqrs => {
  const { takePqrs } = usePqrsService();
  const [handleErrors] = useHandleErrors();
  const [loadingTakePqrs, setLoadingTakePqrs] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successResponse, setSuccessResponse] = useState(false);

  const handleRespondPqrs = useCallback(
    async (ticketNumber: string) => {
      setLoadingTakePqrs(true);
      setError(null);
      setSuccessResponse(false);

      await handleErrors(
        async () => {
          await takePqrs(ticketNumber);
          setSuccessResponse(true);
        },
        async (err) => {
          console.error('Error tomando la PQRS:', err);
          setError('Hubo un error al actualizar el estado de la PQRS');
          setSuccessResponse(false);
        },
        () => {
          setLoadingTakePqrs(false);
        },
      );
    },
    [handleErrors, takePqrs],
  );

  return [handleRespondPqrs, loadingTakePqrs, successResponse, error];
};
