import React from 'react';

import { Button } from '../../atoms/Button';
import { Image } from '../../atoms/Image';
import { Loading } from '../../atoms/Loading';
import Text from '../../atoms/Text';

import { Modal } from '../Modal';
import styles from './styles.module.scss';
import { IPopupProps } from './types';

const Popup: React.FC<IPopupProps> = ({
  isOpen,
  onClose,
  whiteText = 'Cancelar',
  redText = '',
  imageSrc,
  width = 160,
  height = 'auto',
  titleText,
  titleStyles,
  messageText,
  buttons,
  onConfirmation,
  isLoading,
  children,
}) => {
  const handleConfirm = () => {
    if (onConfirmation) {
      onConfirmation('');
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      showCloseIcon={isLoading ? false : true}
    >
      <div
        className={isLoading ? styles.popupLoadingContent : styles.popupContent}
      >
        {imageSrc && !isLoading && (
          <Image srcName={imageSrc} width={width} height={height} />
        )}
        {titleText && !isLoading && (
          <div className={`${titleStyles ? titleStyles : styles.title}`}>
            <Text text={titleText} textStyle='B1-Bold' />
          </div>
        )}
        {messageText && !isLoading && (
          <div className={styles.message}>
            <Text text={messageText} textStyle='B2-Regular' />
          </div>
        )}
        {isLoading ? (
          <Loading size='medium' backgroundColor={true} />
        ) : (
          children
        )}
        {buttons && !isLoading && (
          <div className={styles.buttons}>
            <Button
              text={whiteText}
              onPress={onClose}
              cancelButton={true}
              closeButton
            />
            <Button text={redText} onPress={handleConfirm} changeButton />
          </div>
        )}
      </div>
    </Modal>
  );
};

export { Popup };
