import { Formik } from 'formik';
import { ArrowLeft } from 'iconoir-react';
import * as Yup from 'yup';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../ui/atoms/Button';
import { Icon } from '../../../ui/atoms/Icon';
import { Image } from '../../../ui/atoms/Image';
import { Input } from '../../../ui/atoms/Input';
import { Loading } from '../../../ui/atoms/Loading';
import Text from '../../../ui/atoms/Text';

import { RecoverPassword } from '../../core/hooks/RecoverPassword';
import styles from './styles.module.scss';
import { IInitialValuesForm } from './types';

const ForgotPasswordTemplate = () => {
  const [recoverPswd, isLoading, success] = RecoverPassword();
  const navigate = useNavigate();

  const initialValues: IInitialValuesForm = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Correo electrónico inválido')
      .required('Este campo es requerido'),
  });

  const onSubmit = async (values: IInitialValuesForm) => {
    await recoverPswd(values.email);
  };

  useEffect(() => {
    if (success) {
      navigate('/check-email');
    }
  }, [navigate, success]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Image
          srcName='resiredLogo'
          width={200}
          minWidth={190}
          height={'auto'}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.info}>
            <div className={styles.title}>
              <Icon color='black' onClick={() => navigate('/')}>
                <ArrowLeft />
              </Icon>
              <Text text='¿Olvidaste tu contraseña?' textStyle='SH1-Bold' />
            </div>
            <Text
              text='¡No te preocupes! Estamos aquí para ayudarte. Introduce tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.'
              textStyle='B1-Regular'
            />
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={forgotPasswordSchema}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                <form className={styles.formContainer} onSubmit={handleSubmit}>
                  <Input
                    onChangeText={handleChange('email')}
                    label={'Correo electrónico'}
                    autoCapitalize={'none'}
                    placeholder={'Correo electrónico'}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    error={errors.email}
                    style={styles.input}
                    loginInput
                  />
                  <div className={styles.containerButton}>
                    {isLoading ? (
                      <div className={styles.loaderContainer}>
                        <Loading size='small' backgroundColor={false} />
                      </div>
                    ) : (
                      <Button
                        text='Enviar'
                        onPress={handleSubmit}
                        isLoading={isLoading}
                        changeButton
                        style={styles.button}
                      />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className={styles.image}>
            <Image srcName='resiLook' width={222.5} height={196.5} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ForgotPasswordTemplate };
