import { Attachment as Att, Page } from 'iconoir-react';

import React from 'react';

import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';

interface IAttachmentProps {
  label: string;
  url: string;
}

const Attachment = ({ label, url }: IAttachmentProps) => {
  const type = url.split('.');
  const urlName = url.split('/');
  const name = urlName[urlName.length - 1].split('-');

  const extension = type[type.length - 1];
  const filename = name.slice(2, name.length).join('-');

  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
    return (
      <div className={styles.imageContainer}>
        <div className={styles.title}>
          <Icon color='primary' width={13} height={13}>
            <Att />
          </Icon>
          <Text text={label} textStyle='B2-Bold' color='primary' />
        </div>
        <a
          className={styles.image}
          target='_blank'
          href={url}
          rel='noopener noreferrer'
        >
          <div className={styles.imgContainer}>
            <img src={url} className={styles.newsImage} />
          </div>
          <Text text={filename} textStyle='B1-Bold' color='black' />
        </a>
      </div>
    );
  }

  return (
    <div className={styles.imageContainer}>
      <div className={styles.title}>
        <Icon color='primary' width={13} height={13}>
          <Att />
        </Icon>
        <Text text={label} textStyle='B2-Bold' color='primary' />
      </div>
      <a
        className={styles.image}
        target='_blank'
        href={url}
        rel='noopener noreferrer'
      >
        <Icon color='primary' width={40} height={40}>
          <Page />
        </Icon>
        <Text text={filename} textStyle='B1-Bold' color='black' />
      </a>
    </div>
  );
};

export { Attachment };
