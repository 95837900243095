import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { RequestResidentEntity } from '../entity/RequestResident.entity';
import { useResidentService } from '../service/Residents.service';

export const useResidentsRequests = (
  filter: string,
): [RequestResidentEntity[] | null, boolean, string | null] => {
  const { getResidentsRequests } = useResidentService();
  const [handleErrors] = useHandleErrors();
  const [residentsRequests, setResidentsRequests] = useState<
    RequestResidentEntity[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchResidentsRequests = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getResidentsRequests(filter);
        setResidentsRequests(response.data);
      },
      async (err) => {
        console.error('Error fetching residents requests:', err);
        setError('Hubo un error al obtener las solicitudes de residentes');
      },
      () => {
        setLoading(false);
      },
    );
  }, [handleErrors, getResidentsRequests, filter]);

  useEffect(() => {
    fetchResidentsRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [residentsRequests, loading, error];
};
