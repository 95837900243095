import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { useGuardsService } from '../service/Guards.service';

export const useRemoveGuard = (): [
  (id: number) => Promise<void>,
  boolean,
  string | null,
] => {
  const { removeGuard } = useGuardsService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleRemoveGuard = useCallback(
    async (id: number) => {
      setLoading(true);
      setError(null);
      await handleErrors(
        async () => {
          await removeGuard(id);
        },
        async (err) => {
          console.error('Error removiendo el guarda:', err);
          setError('Hubo un error al remover el guarda');
        },
        () => {
          setLoading(false);
        },
      );
    },
    [handleErrors, removeGuard],
  );

  return [handleRemoveGuard, loading, error];
};
