import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { NewsEntity } from '../entity/News.entity';
import { useNewsService } from '../service/News.service';
import { IUseGetNews } from './types';

export const useNews = (): IUseGetNews => {
  const { getNews } = useNewsService();
  const [handleErrors] = useHandleErrors();
  const [news, setnews] = useState<NewsEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPqrs = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getNews();
        setnews(response.data.news);
      },
      async (err) => {
        console.error('Error obteniendo las noticias:', err);
        setError('Hubo un error al obtener las noticias');
        setnews([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [getNews, handleErrors]);

  return [fetchPqrs, news, loading, error];
};
