import { AxiosResponse } from 'axios';

import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../feature/authentication/core/context/Authentication.context';
import { User } from '../../feature/authentication/core/entity/User.entity';
import { LocalStorageVault } from '../LocalStorageVault';
import { useAxiosInstance } from '../axios';
import { encryptData } from '../encryption';
import { ErrorsResponse, IUseActionsHandleErrors } from './types';

export const useActionsHandleErrors = (): IUseActionsHandleErrors => {
  const vault = new LocalStorageVault();
  const navigate = useNavigate();

  const { axiosInstanceWithoutSession } = useAxiosInstance();
  const { authState, setAuthState } = useAuthContext();

  const errorOptions: ErrorsResponse[] = [
    {
      code: 401,
      message: 'Correo y/o contraseña incorrectos.',
    },
    {
      code: 403,
      message: 'Sesión inválida.',
      action: async () => {
        await renovateToken();

        window.location.reload();
      },
      redirectTo: '/login',
    },
    {
      code: 404,
      message: 'Página no encontrada.',
      redirectTo: '/404',
    },
  ];

  const logOut = () => {
    vault.resetStorage('auth');
    setAuthState(undefined);
    navigate('/');
  };

  const renovateToken = async () => {
    try {
      const response = await axiosInstanceWithoutSession().post(
        '/auth/admin/refresh-token',
        {
          refresh_token: authState?.refresh_token,
        },
      );

      const updatedUser: User = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        user_email: authState!.user_email,
        mandatory_change: authState!.mandatory_change,
        user_id: authState!.user_id,
        user_name: authState!.user_name,
        roles: authState!.roles,
      };

      setAuthState(updatedUser);

      await vault.resetStorage('auth');
      await vault.saveValueInStorage(
        'auth',
        encryptData(JSON.stringify(updatedUser))!,
      );
    } catch (error) {
      console.log(error);

      logOut();
    }
  };

  const getErrorMessage = (
    error: AxiosResponse | undefined,
    customError?: ErrorsResponse[],
  ): ErrorsResponse | undefined => {
    const status = error?.status;
    console.log('Status:', status);

    if (customError) {
      const result =
        customError.find(({ code }) => code === status) || undefined;
      if (result) {
        return result;
      }
    }

    return errorOptions.find(({ code }) => code === status) || undefined;
  };

  return [getErrorMessage];
};
