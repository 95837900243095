import React from 'react';

import { Image } from '../../../../feature/ui/atoms/Image';
import Text from '../../../../feature/ui/atoms/Text';

import styles from './styles.module.scss';

const PageNotFoundTemplate = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <Image srcName='pageNotFound' width={'23vw'} height={'auto'} />
        </div>
        <div className={styles.textContainer}>
          <Text text='¡Oops!' textStyle='SH1-Bold' />
          <Text
            text='Lo sentimos, la página que buscas no está disponible... '
            textStyle='B1-Regular'
          />
          <Text
            text='Utiliza el menú de la izquierda para navegar a otras secciones del sitio'
            textStyle='B1-Regular'
          />
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundTemplate;
