import { useCallback, useEffect, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { HomeEntity } from '../entity/Home.entity';
import { useNeighborhoodService } from '../service/Neighborhood.service';
import { IUseGetHomes } from './types';

export const useHomes = (): IUseGetHomes => {
  const { getHomes } = useNeighborhoodService();
  const [handleErrors] = useHandleErrors();
  const [homes, setHomes] = useState<HomeEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchHomes = useCallback(async () => {
    setLoading(true);
    await handleErrors(
      async () => {
        const response = await getHomes();
        setHomes(response.data);
      },
      async () => {
        setHomes([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [handleErrors, getHomes]);

  useEffect(() => {
    fetchHomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [homes, loading, fetchHomes];
};
