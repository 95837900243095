import { AxiosResponse } from 'axios';

import { useAxiosInstance } from '../../../../utils/axios';

import { PackageStatus } from '../enums/PackageStatus.enum';
import { PackageReportUseCase } from '../usecase/PackageReport.usecase';

export const usePackageReportService = (): PackageReportUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getPackageReport = async (
    status: PackageStatus,
  ): Promise<AxiosResponse> => {
    return await axiosInstance().get(`/admin/report/packages?status=${status}`);
  };

  return {
    getPackageReport,
  };
};
