import React, { useEffect, useState } from 'react';

import { Button } from '../../atoms/Button';
import Dropdown from '../../atoms/Dropdown';
import { Input } from '../../atoms/Input';

import { Modal } from '../../molecules/Modal';
import { Popup } from '../../molecules/Popup';

import { AddCategoryNotificationDTO } from '../../../notifications/core/dto/AddCategoryNotification.dto';
import { CategoryNotificationEntity } from '../../../notifications/core/entity/CategoryNotification.entity';
import { NotificationPriority } from '../../../notifications/core/enums/NotificationPriority.enum';
import styles from './styles.module.scss';

interface CategoryDropdownProps {
  categories: { label: string; value: string }[];
  onSelectedCategory: (category: string) => void;
  onAddCategory: (newCategory: AddCategoryNotificationDTO) => void;
  addIsLoading?: boolean;
  successCreation?: boolean;
}

const CategoryDropdown: React.FC<CategoryDropdownProps> = ({
  categories,
  onSelectedCategory,
  onAddCategory,
  addIsLoading,
  successCreation,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    default_message: '',
    default_title: '',
    priority: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    default_message: '',
    default_title: '',
    priority: '',
  });

  const priorityOptions = [
    { label: 'Alta', value: NotificationPriority.HIGH },
    { label: 'Media', value: NotificationPriority.MEDIUM },
    { label: 'Baja', value: NotificationPriority.LOW },
  ];

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
    onSelectedCategory(value);
  };

  const handleFormChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const handleFormClose = () => {
    setIsModalOpen(false);
    setFormData({
      name: '',
      default_message: '',
      default_title: '',
      priority: '',
    });
    setErrors({
      name: '',
      default_message: '',
      default_title: '',
      priority: '',
    });
  };

  const handleFormSubmit = async () => {
    const newErrors = {
      name: formData.name ? '' : 'Este campo es requerido',
      default_message: formData.default_message
        ? ''
        : 'Este campo es requerido',
      default_title: formData.default_title ? '' : 'Este campo es requerido',
      priority: formData.priority ? '' : 'Este campo es requerido',
    };

    setErrors(newErrors);
    if (
      !formData.name ||
      !formData.default_message ||
      !formData.default_title ||
      !formData.priority
    ) {
      return;
    }

    onAddCategory(formData as CategoryNotificationEntity);
    handleFormClose();
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
  };

  useEffect(() => {
    if (successCreation) {
      setIsSuccessPopupOpen(true);
    }
  }, [successCreation]);

  return (
    <>
      <Dropdown
        label='Categoría'
        options={categories}
        selectedValue={selectedCategory}
        onValueChange={handleCategoryChange}
        button
        buttonText='+ Crear categoría'
        buttonAction={() => setIsModalOpen(true)}
        styleContainer={styles.dropdown}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        showCloseIcon={false}
        isLoading={addIsLoading}
      >
        <div className={styles.form}>
          <div className={styles.container}>
            <div>
              <Input
                onChangeText={(value) => handleFormChange('name', value)}
                label={'Nombre de la categoría'}
                modernStyle
                labelTextColor='gray'
                error={errors.name}
              />
              <Input
                onChangeText={(value) =>
                  handleFormChange('default_message', value)
                }
                label={'Mensaje'}
                modernStyle
                labelTextColor='gray'
                error={errors.default_message}
              />
            </div>
            <div>
              <Input
                onChangeText={(value) =>
                  handleFormChange('default_title', value)
                }
                label={'Título'}
                modernStyle
                labelTextColor='gray'
                error={errors.default_title}
              />
              <Dropdown
                label='Nivel de prioridad'
                labelColor='gray'
                options={priorityOptions}
                selectedValue={formData.priority}
                onValueChange={(value) => handleFormChange('priority', value)}
                error={errors.priority}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button text={'Cerrar'} onPress={handleFormClose} closeButton />
            <Button text={'Crear'} changeButton onPress={handleFormSubmit} />
          </div>
        </div>
      </Modal>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Categoría agregada con éxito!'
        buttons={false}
      />
    </>
  );
};

export default CategoryDropdown;
