import classNames from 'classnames';

import React from 'react';

import styles from './styles.module.scss';
import { ITextProps } from './types';

const Text: React.FC<ITextProps> = ({
  text,
  children,
  textStyle,
  color,
  className,
}) => {
  const textClass = classNames(
    styles.text,
    {
      [styles[`text--${textStyle.toLowerCase()}`]]: textStyle,
      [styles[`text--${color}`]]: color,
    },
    className,
  );

  return <span className={textClass}>{children || text}</span>;
};

export default Text;
