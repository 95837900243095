import React from 'react';

import { PackageReportTable } from '../../../../feature/packageReport/templates/PackageReportTable';

import styles from './styles.module.scss';

const PackagesTemplate = () => {
  return (
    <div className={styles.content}>
      <PackageReportTable />
    </div>
  );
};

export default PackagesTemplate;
