import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { AddHomeDTO } from '../dto/AddHome.dto';
import { NeighborhoodUseCase } from '../usecase/Neighborhood.usecase';

export const useNeighborhoodService = (): NeighborhoodUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getHomes = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get('/admin/homes');
  }, [axiosInstance]);

  const vacateHome = useCallback(
    async (homeId: number): Promise<AxiosResponse> => {
      return await axiosInstance().delete(`/admin/home/${homeId}/residents`);
    },
    [axiosInstance],
  );

  const getResidents = useCallback(
    async (homeId: number): Promise<AxiosResponse> => {
      return await axiosInstance().get(`/admin/home/${homeId}/residents`);
    },
    [axiosInstance],
  );

  const updateHomeName = useCallback(
    async (homeId: number, name: string): Promise<AxiosResponse> => {
      return await axiosInstance().put('/admin/home', { home: homeId, name });
    },
    [axiosInstance],
  );

  const getBlocks = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get('/admin/blocks');
  }, [axiosInstance]);

  const createHome = useCallback(
    async (data: AddHomeDTO): Promise<AxiosResponse> => {
      return await axiosInstance().post('/admin/home', data);
    },
    [axiosInstance],
  );

  return {
    getHomes,
    vacateHome,
    getResidents,
    updateHomeName,
    getBlocks,
    createHome,
  };
};
