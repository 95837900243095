import { LogOut, Neighbourhood, Settings } from 'iconoir-react';

import React, { useState } from 'react';

import { Icon } from '../../../ui/atoms/Icon';

import { ITopBarItems } from '../../../ui/molecules/TopBar/types';

import { useAuthContext } from '../../../authentication/core/context/Authentication.context';

const TopbarHook = () => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isNeighborhoobChange, setIsNeighborhoobChange] = useState(false);
  const { logout, authState } = useAuthContext();

  const userName = authState?.user_name || 'Admin';
  const neighborhoodName = authState?.roles.find((nh) => {
    return nh.active;
  })?.neighborhood_name;

  const menuItems = [
    {
      label: 'Cambiar contraseña',
      onClick: () => {
        setIsPasswordModalOpen(true);
      },
      icon: (
        <Icon color={'black'} width={20} height={20}>
          <Settings />
        </Icon>
      ),
    },
    {
      label: 'Cambiar conjunto residencial',
      onClick: () => {
        setIsNeighborhoobChange(true);
      },
      icon: (
        <Icon color={'black'} width={20} height={20}>
          <Neighbourhood />
        </Icon>
      ),
    },
    {
      label: 'Cerrar sesión',
      onClick: () => logout(),
      icon: (
        <Icon color={'black'} width={20} height={20}>
          <LogOut />
        </Icon>
      ),
    },
  ];

  const getMenuItems = (): ITopBarItems[] => {
    if (authState && authState.roles.length <= 1) {
      return menuItems.filter(
        (val) => val.label !== 'Cambiar conjunto residencial',
      );
    }

    return menuItems;
  };

  return {
    isPasswordModalOpen,
    isNeighborhoobChange,
    getMenuItems,
    setIsPasswordModalOpen,
    setIsNeighborhoobChange,
    userName,
    neighborhoodName,
  };
};

export { TopbarHook };
