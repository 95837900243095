import { PageLeft, PageRight } from 'iconoir-react';

import React from 'react';

import { Loading } from '../Loading';
import Text from '../Text';
import styles from './styles.module.scss';
import { IButtonProps } from './types';

const Button = ({
  text,
  textStyle = 'B1-Medium',
  icon,
  iconBack,
  iconContinue,
  onPress,
  style,
  popup,
  cancelButton,
  disabledButton,
  closeButton,
  changeButton,
  isLoading,
}: IButtonProps) => {
  return (
    <div
      className={`${styles.container} ${
        cancelButton
          ? styles.cancelButton
          : disabledButton
            ? styles.disabledButton
            : ''
      } ${closeButton ? styles.closeButton : ''} ${changeButton ? styles.changeButton : ''} ${
        isLoading ? styles.loading : ''
      } ${style}`}
      onClick={isLoading || disabledButton ? undefined : onPress}
    >
      <div
        className={`${popup ? styles.popupContainer : styles.defaultContainer}`}
      >
        <div className={`${styles.content} ${isLoading ? styles.hidden : ''}`}>
          {iconBack && <PageLeft color={'black'} />}
          {icon && <div>{icon}</div>}
          <Text
            text={text}
            textStyle={textStyle}
            color={closeButton ? 'black' : 'neutral'}
          />
          {iconContinue && <PageRight color={'white'} />}
        </div>
        {isLoading && (
          <div className={styles.loaderOverlay}>
            <Loading size='small' backgroundColor={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export { Button };
