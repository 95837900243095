import React from 'react';

import { ResidentsRequestsTable } from '../../../../feature/residents/templates/ResidentsRequestsTable';

import styles from './styles.module.scss';

const RequestsTemplate = () => {
  return (
    <div className={styles.content}>
      <ResidentsRequestsTable />
    </div>
  );
};

export default RequestsTemplate;
