import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { ResidentEntity } from '../entity/Resident.entity';
import { useNeighborhoodService } from '../service/Neighborhood.service';
import { IUseGetResidents } from './types';

export const useResidents = (): IUseGetResidents => {
  const { getResidents } = useNeighborhoodService();
  const [handleErrors] = useHandleErrors();
  const [residents, setResidents] = useState<ResidentEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchResidents = useCallback(
    async (homeId: number) => {
      setLoading(true);
      setError(null);

      await handleErrors(
        async () => {
          const response = await getResidents(homeId);
          setResidents(response.data);
        },
        async (err) => {
          console.error('Error obteniendo los residentes:', err);
          setError('Hubo un error al obtener los residentes');
          setResidents([]);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [getResidents, handleErrors],
  );

  return [fetchResidents, residents, loading, error];
};
