import { SquareCursor } from 'iconoir-react';

import React from 'react';
import ShowMoreText from 'react-show-more-text';

import { Button } from '../../../ui/atoms/Button';
import { Icon } from '../../../ui/atoms/Icon';
import Text from '../../../ui/atoms/Text';

import { Attachment } from '../../../ui/molecules/Attachment';

import { PqrsState } from '../../core/enums/PqrsState.enum';
import styles from './styles.module.scss';

const getStateText = (state: PqrsState) => {
  switch (state) {
    case PqrsState.RADICADA:
      return 'Radicada';
    case PqrsState.EN_REVISION:
      return 'En Revisión';
    case PqrsState.COMPLETADA:
      return 'Completada';
    default:
      return '';
  }
};

const getStateStyles = (state: PqrsState) => {
  switch (state) {
    case PqrsState.RADICADA:
      return styles.textRadicada;
    case PqrsState.EN_REVISION:
      return styles.textRevision;
    case PqrsState.COMPLETADA:
      return styles.textCompleted;
    default:
      return '';
  }
};

interface IPetitionCard {
  title: string;
  category: string;
  ticket: string;
  state: PqrsState;
  response: string | null;
  description: string;
  created_at: string;
  response_at: string | null;
  admin_response: string | null;
  details?: string;
  resident: string;
  home: string;
  onButtonClick?: () => void;
  isLoading?: boolean;
}

const PetitionCard: React.FC<IPetitionCard> = ({
  title,
  category,
  ticket,
  state,
  description,
  created_at,
  details,
  onButtonClick,
  isLoading,
}) => {
  console.log(details);
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.left}>
          <ShowMoreText
            lines={2}
            expanded={false}
            width={200}
            className={styles.title}
            more='Ver más'
            less='Ver menos'
            anchorClass={styles.titleShowMore}
          >
            <Text text={title} textStyle='SH2-Bold' color='primary' />
          </ShowMoreText>
          <Text text={category} textStyle='B3-Bold' color='primary' />
          <div className={styles.status}>
            <Text
              text={getStateText(state)}
              textStyle='B3-Regular'
              className={getStateStyles(state)}
            />
          </div>
        </div>
        <div className={styles.right}>
          <Text
            text={new Date(created_at).toLocaleDateString()}
            textStyle='B2-Medium'
            color='black'
          />
          <Text
            text={`Ticket no. ${ticket}`}
            textStyle='B2-Medium'
            color='secondary1'
          />
        </div>
      </div>
      <div className={styles.content}>
        <ShowMoreText
          lines={2}
          expanded={false}
          className={styles.description}
          more='Ver más'
          less='Ver menos'
          anchorClass={styles.descShowMore}
        >
          <Text text={description} textStyle='B1-Regular' color='black' />
        </ShowMoreText>
        {details && <Attachment label='Archivos adjuntos' url={details} />}
        {state === PqrsState.RADICADA && (
          <Button
            text='En Revisión'
            textStyle='B2-Medium'
            onPress={onButtonClick}
            icon={
              <Icon color='neutral' height={18} width={18}>
                <SquareCursor />
              </Icon>
            }
            style={styles.button}
            cancelButton
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export { PetitionCard };
