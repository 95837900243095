import {
  Bell,
  Community,
  DomoticWarning,
  Group,
  HeadsetHelp,
  Menu,
  MenuScale,
  Neighbourhood,
  NewTab,
  Package,
  Reports,
  UserBadgeCheck,
  UserBag,
  UserPlus,
} from 'iconoir-react';

import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';

import SidebarItem from '../../molecules/SidebarItem';
import { SidebarItemProps as Item } from '../../molecules/SidebarItem/types';

import { SidebarContext } from './context/SidebarContext';
import styles from './styles.module.scss';

const items: Omit<Item, 'isActive' | 'onClick' | 'color'>[] = [
  {
    id: '/neighborhood',
    label: 'Mi conjunto',
    icon: <Neighbourhood />,
    path: '/neighborhood',
  },
  {
    id: '/guards',
    label: 'Guardas',
    icon: <UserBag />,
    path: '/guards',
  },
  {
    id: '/residents',
    label: 'Residentes',
    icon: <Community />,
    path: '#',
    subItems: [
      {
        id: '/residents/my',
        label: 'Mis residentes',
        icon: <Group />,
        path: '/residents',
      },
      {
        id: '/residents/requests',
        label: 'Solicitudes',
        icon: <UserBadgeCheck />,
        path: '/residents/requests',
      },
    ],
  },
  {
    id: '/news',
    label: 'Novedades',
    icon: <DomoticWarning />,
    path: '#',
    subItems: [
      {
        id: '/news/notices',
        label: 'Noticias',
        icon: <NewTab />,
        path: '/news',
      },
      {
        id: '/news/notifications',
        label: 'Notificaciones',
        icon: <Bell />,
        path: '/news/notifications',
      },
    ],
  },
  { id: '/pqrs', label: 'PQRS', icon: <HeadsetHelp />, path: '/pqrs' },
  {
    id: '/reports',
    label: 'Reportes',
    icon: <Reports />,
    path: '#',
    subItems: [
      {
        id: '/reports/packages',
        label: 'Paquetes',
        icon: <Package />,
        path: '/reports/packages',
      },
      {
        id: '/reports/visits',
        label: 'Visitas',
        icon: <UserPlus />,
        path: '/reports/visits',
      },
    ],
  },
];

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const [activeItem, setActiveItem] = useState<string>(location.pathname);

  const handleItemClick = (id: string) => {
    setActiveItem(id);
  };

  return (
    <div
      className={`${styles.sidebar} ${isSidebarOpen ? styles.open : styles.closed}`}
    >
      <button className={styles.toggleButton} onClick={toggleSidebar}>
        {isSidebarOpen ? (
          <div className={styles.closeIcon}>
            <Icon color='primary'>
              <MenuScale />
            </Icon>
          </div>
        ) : (
          <Icon color='primary'>
            <Menu />
          </Icon>
        )}
      </button>
      <div className={styles.logo}>
        {isSidebarOpen ? (
          <Image srcName={'resiredLogo'} width={'auto'} height={'5vh'} />
        ) : null}
      </div>
      <ul className={styles.menu}>
        {isSidebarOpen
          ? items.map((item) => (
              <SidebarItem
                key={item.id}
                id={item.id}
                label={item.label}
                icon={item.icon}
                path={item.path}
                isActive={activeItem.startsWith(item.id)}
                onClick={handleItemClick}
                color={
                  activeItem.startsWith(item.id) ? 'primary' : 'secondary2'
                }
                subItems={item.subItems}
              />
            ))
          : null}
      </ul>
    </div>
  );
};

export default Sidebar;
