import { Formik } from 'formik';
import * as Yup from 'yup';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../ui/atoms/Button';
import { Image } from '../../../ui/atoms/Image';
import { Input } from '../../../ui/atoms/Input';
import { Loading } from '../../../ui/atoms/Loading';
import Text from '../../../ui/atoms/Text';

import { ResetPassword } from '../../core/hooks/ResetPassword';
import styles from './styles.module.scss';

const ResetPswdTemplate = () => {
  const [resetPswd, isLoading, success] = ResetPassword();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Este campo es requerido'),
    confirmPassword: Yup.string()
      .required('Este campo es requerido')
      .test(
        'passwords-match',
        'Las contraseñas no coinciden',
        function (value) {
          return value === this.parent.password;
        },
      ),
  });

  const onSubmit = async (values: { password: string }) => {
    if (token) {
      await resetPswd(token, values.password);
    }
  };

  useEffect(() => {
    if (success) {
      navigate('/login');
    }
  }, [navigate, success]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Image
          srcName='resiredLogo'
          width={200}
          minWidth={190}
          height={'auto'}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.info}>
            <Text text='Restablecer contraseña' textStyle='SH1-Bold' />
            <Text
              text='Ingresa y confirma tu nueva contraseña.'
              textStyle='B1-Regular'
            />
            <Formik
              initialValues={initialValues}
              validationSchema={resetPasswordSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                <form className={styles.formContainer} onSubmit={handleSubmit}>
                  <Input
                    onChangeText={handleChange('password')}
                    label={'Nueva contraseña'}
                    autoCapitalize={'none'}
                    placeholder={'Contraseña'}
                    onBlur={handleBlur('password')}
                    value={values.password}
                    error={errors.password}
                    style={styles.input}
                    secureTextEntry
                  />
                  <Input
                    onChangeText={handleChange('confirmPassword')}
                    label={'Confirmar contraseña'}
                    autoCapitalize={'none'}
                    placeholder={'Confirmar contraseña'}
                    onBlur={handleBlur('confirmPassword')}
                    value={values.confirmPassword}
                    error={errors.confirmPassword}
                    style={styles.input}
                    secureTextEntry
                  />
                  <div className={styles.containerButton}>
                    {isLoading ? (
                      <div className={styles.loaderContainer}>
                        <Loading size='small' backgroundColor={false} />
                      </div>
                    ) : (
                      <Button
                        text='Enviar'
                        onPress={handleSubmit}
                        isLoading={isLoading}
                        style={styles.button}
                        changeButton
                      />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className={styles.image}>
            <Image srcName='resiLaptop' width={251} height={171} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ResetPswdTemplate };
