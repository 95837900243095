import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { BlocksResponseDTO } from '../dto/BlocksResponse.dto';
import { BlockEntity } from '../entity/Block.entity';
import { useNeighborhoodService } from '../service/Neighborhood.service';
import { IUseGetBlocks } from './types';

export const useGetBlocks = (): IUseGetBlocks => {
  const { getBlocks } = useNeighborhoodService();
  const [handleErrors] = useHandleErrors();
  const [blocks, setBlocks] = useState<BlockEntity[]>([]);
  const [groupingType, setGroupingType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBlocks = useCallback(async () => {
    setLoading(true);
    setError(null);
    await handleErrors(
      async () => {
        const response = await getBlocks();
        const data: BlocksResponseDTO = response.data;
        setBlocks(data.blocks);
        setGroupingType(data.grouping_type);
      },
      async (err) => {
        console.error('Error obteniendo los bloques residenciales:', err);
        setError('Hubo un error al obtener los bloques residenciales');
        setBlocks([]);
        setGroupingType('');
      },
      () => {
        setLoading(false);
      },
    );
  }, [getBlocks, handleErrors]);

  return [fetchBlocks, blocks, groupingType, loading, error];
};
