import { useCallback, useEffect, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { GuardEntity } from '../entity/Guard.entity';
import { useGuardsService } from '../service/Guards.service';
import { IUseGuards } from './types';

export const useGuards = (active: boolean): IUseGuards => {
  const { getGuards } = useGuardsService();
  const [handleErrors] = useHandleErrors();
  const [guards, setGuards] = useState<GuardEntity[] | null>(null);
  const [guardsLoading, setGuardsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchGuards = useCallback(async () => {
    setGuardsLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getGuards(active);
        setGuards(response.data);
      },
      async (err) => {
        console.error('Error obteniendo los guardas:', err);
        setError('Hubo un error al obtener los guardas');
        setGuards([]);
      },
      () => {
        setGuardsLoading(false);
      },
    );
  }, [handleErrors, getGuards, active]);

  useEffect(() => {
    fetchGuards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return [guards, guardsLoading, fetchGuards, error];
};
