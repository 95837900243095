import React from 'react';
import ReactLoading from 'react-loading';

import styles from './styles.module.scss';
import { ILoadingProps } from './types';

const Loading = ({
  size = 'large',
  type = 'spin',
  backgroundColor = false,
}: ILoadingProps) => {
  const width = size === 'small' ? 20 : size === 'medium' ? 40 : 60;
  const height = size === 'small' ? 20 : size === 'medium' ? 40 : 60;
  return (
    <ReactLoading
      type={type}
      color={'#b4282b'}
      width={width}
      height={height}
      className={`${styles.loaderContainer} ${backgroundColor ? styles.background : ''}`}
    />
  );
};

export { Loading };
