import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { useNeighborhoodService } from '../service/Neighborhood.service';
import { IUseUpdateHomeName } from './types';

export const useUpdateHomeName = (): IUseUpdateHomeName => {
  const { updateHomeName } = useNeighborhoodService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleUpdateHomeName = useCallback(
    async (homeId: number, name: string) => {
      setLoading(true);
      setError(null);

      await handleErrors(
        async () => {
          await updateHomeName(homeId, name);
          window.location.reload();
        },
        async () => {
          console.error('Error actualizando el nombre del hogar:', error);
          setError('Hubo un error al actualizar el nombre del hogar');
        },
        () => {
          setLoading(false);
        },
      );
    },
    [error, handleErrors, updateHomeName],
  );

  return [handleUpdateHomeName, loading, error];
};
