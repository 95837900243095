import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { PasswordService } from '../service/Password.service';
import { IUseRecoverPassword } from './types';

export const RecoverPassword = (): IUseRecoverPassword => {
  const { recoverPswd } = PasswordService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleRecoverPassword = useCallback(
    async (email: string) => {
      setSuccess(false);
      setLoading(true);
      setError(null);
      try {
        await recoverPswd(email);
        setSuccess(true);
      } catch (err) {
        console.error('Error solicitando recuperación de clave:', err);
        setError('Hubo un error solicitando recuperación de clave');
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    },
    [recoverPswd],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleRecoverPassword, loading, success, error];
};
