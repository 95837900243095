import { Formik } from 'formik';
import * as Yup from 'yup';

import React from 'react';

import { Button } from '../../atoms/Button';
import Dropdown from '../../atoms/Dropdown';
import { Image } from '../../atoms/Image';
import { Input } from '../../atoms/Input';
import { Loading } from '../../atoms/Loading';

import styles from './styles.module.scss';
import { IPopupFormProps } from './types';

const optionsDocumentType = [
  { label: 'Cédula de ciudadanía', value: 'CC' },
  { label: 'Cédula de extranjería', value: 'CE' },
  { label: 'Tarjeta de identidad', value: 'TI' },
  { label: 'Pasaporte', value: 'PA' },
];

const validationSchemaGuard = Yup.object().shape({
  first_name: Yup.string().required('Este campo es requerido'),
  last_name: Yup.string().required('Este campo es requerido'),
  document_type: Yup.string().required('Este campo es requerido'),
  document_id: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('Este campo es requerido'),
});

const validationSchemaResident = Yup.object().shape({
  first_name: Yup.string().required('Este campo es requerido'),
  last_name: Yup.string().required('Este campo es requerido'),
  document_type: Yup.string().required('Este campo es requerido'),
  document_id: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('Este campo es requerido'),
  home_id: Yup.string().required('Este campo es requerido'),
});

const PopupForm: React.FC<IPopupFormProps> = ({
  type,
  formData,
  onSubmit,
  onClose,
  isLoading,
  homeOptions,
}) => {
  return (
    <Formik
      initialValues={formData}
      validationSchema={
        type === 'resident' ? validationSchemaResident : validationSchemaGuard
      }
      onSubmit={onSubmit}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
        <form className={styles.container} onSubmit={handleSubmit}>
          {isLoading && (
            <div className={styles.loadingOverlay}>
              <Loading size='medium' backgroundColor={true} />
            </div>
          )}
          <div
            className={`${styles.formContent} ${isLoading ? styles.blurred : ''}`}
          >
            <div className={styles.avatar}>
              <Image
                srcName={type === 'resident' ? 'residentAvatar' : 'guardAvatar'}
                width={60}
                height={60}
              />
            </div>
            <div>
              <div className={styles.form}>
                <Input
                  label='Nombres *'
                  placeholder='Nombres'
                  value={values.first_name}
                  onChangeText={handleChange('first_name')}
                  onBlur={handleBlur('first_name')}
                  error={errors.first_name}
                  modernStyle
                  maxLength={25}
                />
                <Input
                  label='Apellidos *'
                  placeholder='Apellidos'
                  value={values.last_name}
                  onChangeText={handleChange('last_name')}
                  onBlur={handleBlur('last_name')}
                  error={errors.last_name}
                  modernStyle
                  maxLength={25}
                />
                <Dropdown
                  label='Tipo de documento *'
                  options={optionsDocumentType}
                  selectedValue={values.document_type}
                  onValueChange={handleChange('document_type')}
                  error={errors.document_type}
                />
                <Input
                  label='Número de documento *'
                  placeholder='Número de documento'
                  value={values.document_id}
                  onChangeText={handleChange('document_id')}
                  onBlur={handleBlur('document_id')}
                  error={errors.document_id}
                  modernStyle
                  maxLength={20}
                />
                <Input
                  label='Correo electrónico *'
                  placeholder='Correo electrónico'
                  value={values.email}
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  error={errors.email}
                  modernStyle
                  maxLength={100}
                />
                {type === 'resident' && (
                  <>
                    {homeOptions && (
                      <Dropdown
                        label='Seleccionar residencia *'
                        options={homeOptions}
                        selectedValue={values.home_id}
                        onValueChange={handleChange('home_id')}
                        searchEnabled={true}
                        error={errors.home_id}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={styles.buttons}>
                <Button text='Cerrar' onPress={onClose} popup closeButton />
                <Button
                  text={
                    type === 'resident' ? 'Añadir residente' : 'Añadir guarda'
                  }
                  onPress={handleSubmit}
                  popup
                  changeButton
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export { PopupForm };
