type TColor =
  | 'primary'
  | 'secondary1'
  | 'secondary2'
  | 'secondary3'
  | 'tertiary1'
  | 'tertiary2'
  | 'tertiary3'
  | 'tertiary4'
  | 'tertiary5'
  | 'neutral'
  | 'gray'
  | 'grayText'
  | 'black'
  | 'clearGray'
  | 'superClearGray';

const colors: Record<TColor, string> = {
  primary: '#B4282B',
  secondary1: '#D93744',
  secondary2: '#27262B',
  secondary3: '#F94949',
  tertiary1: '#FF8982',
  tertiary2: '#EF949B',
  tertiary3: '#8F8B9F',
  tertiary4: '#3B0909',
  tertiary5: '#733330',
  neutral: '#FFFFFF',
  gray: '#6B779A',
  grayText: '#9796A1',
  black: '#191B23',
  clearGray: '#E8E9EA',
  superClearGray: '#F8F8FA',
};

export type { TColor };
export { colors };
