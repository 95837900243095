// import { EditPencil } from 'iconoir-react';
import React, { useEffect, useState } from 'react';

import { LinkOrButton } from '../../ui/atoms/LinkOrButton';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';

import { ListEmpty } from '../../ui/molecules/ListEmpty';
import { Popup } from '../../ui/molecules/Popup';

import Table from '../../ui/organisms/Table';

import { useResidents } from '../core/hooks/GetResidents.hook';
import { useRemoveResident } from '../core/hooks/RemoveResidents.hook';
import styles from './styles.module.scss';

const ResidentsTable: React.FC = () => {
  const [active, setActive] = useState(true);
  const [residents, loading, fetchResidents] = useResidents(active);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResidents, setFilteredResidents] = useState(residents || []);
  const [removePopUpOpen, setRemovePopUpOpen] = useState(false);
  const [removedSuccessPopUpOpen, setRemovedSuccessPopUpOpen] = useState(false);
  const [residentToRemove, setResidentToRemove] = useState<number | null>(null);
  const [handleRemoveResident, removeLoading] = useRemoveResident();

  useEffect(() => {
    if (residents) {
      setFilteredResidents(
        residents.filter(
          (resident) =>
            resident.first_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            resident.last_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            resident.document_id.includes(searchTerm),
        ),
      );
    }
  }, [searchTerm, residents]);

  const titleCase = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  const columns = [
    { title: 'Nombre', sortable: true, type: 'string' as const },
    { title: 'Apellido', sortable: true, type: 'string' as const },
    { title: 'Correo', sortable: true, type: 'string' as const },
    { title: '# Documento', sortable: true, type: 'string' as const },
    { title: 'Apartamento', sortable: true, type: 'string' as const },
  ];

  const data =
    filteredResidents?.map((resident) => [
      { text: titleCase(resident.first_name) },
      { text: titleCase(resident.last_name) },
      { text: resident.email },
      { text: resident.document_id },
      { text: resident.house },
    ]) || [];

  const openRemovePopup = (id: number) => {
    setResidentToRemove(id);
    setRemovePopUpOpen(true);
  };

  const handleCloseConfirmPopup = () => {
    setRemovePopUpOpen(false);
    setResidentToRemove(null);
  };

  const handleCloseRemovedPopup = () => {
    setRemovedSuccessPopUpOpen(false);
    fetchResidents();
  };

  const handleConfirmRemoveResident = async () => {
    if (residentToRemove !== null) {
      await handleRemoveResident(residentToRemove);
      handleCloseConfirmPopup();
      setRemovedSuccessPopUpOpen(true);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.topContainer}>
        <div className={styles.filterContainer}>
          <LinkOrButton
            text='Activos'
            textStyle='B1-Medium'
            onClick={() => setActive(true)}
            className={active ? styles.active : ''}
            color={active ? 'primary' : 'secondary2'}
            filter={true}
            active={active}
          />
          <LinkOrButton
            text='Inactivos'
            textStyle='B1-Medium'
            onClick={() => setActive(false)}
            className={!active ? styles.inactive : ''}
            color={!active ? 'primary' : 'secondary2'}
            filter={true}
            active={!active}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            placeholder='Buscar residentes por nombre, apellido o documento'
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {loading ? (
          <Loading size='small' backgroundColor={true} />
        ) : filteredResidents.length > 0 ? (
          <Table
            imageSrc='resident'
            columns={columns}
            data={data}
            firstIcon={/* active ? <EditPencil /> :  */ null}
            secondIcon={active ? 'lock' : null}
            secondAction={(rowIndex: number) =>
              openRemovePopup(filteredResidents[rowIndex].id)
            }
          />
        ) : (
          <ListEmpty />
        )}
      </div>
      <Popup
        isOpen={removePopUpOpen}
        onClose={handleCloseConfirmPopup}
        imageSrc={'resiLook'}
        titleText='¿Deseas deshabilitar a este residente?'
        whiteText='Cerrar'
        width={180}
        redText='Confirmar'
        buttons={true}
        onConfirmation={handleConfirmRemoveResident}
        isLoading={removeLoading}
      />
      <Popup
        isOpen={removedSuccessPopUpOpen}
        onClose={handleCloseRemovedPopup}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Residente deshabilitado con éxito!'
        buttons={false}
      />
    </div>
  );
};

export { ResidentsTable };
