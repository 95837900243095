import axios, { AxiosResponse } from 'axios';

import { useNavigate } from 'react-router-dom';

import { LocalStorageVault } from '../../../../utils/LocalStorageVault';
import { encryptData } from '../../../../utils/encryption';

import { ENV_VARIABLES } from '../../../../config/env';
import { useAuthContext } from '../context/Authentication.context';
import { AuthRequestDTO } from '../dto/AuthRequest.dto';
import { AuthResponseDTO } from '../dto/AuthResponse.dto';
import { SignInUseCase } from '../usecase/SignIn.usecase';

export class AuthenticationService implements SignInUseCase {
  private authInfo = useAuthContext();
  private storage = new LocalStorageVault();
  private navigate = useNavigate();

  async signInWithCredentials(auth: AuthRequestDTO): Promise<AxiosResponse> {
    return await axios.post(
      `${ENV_VARIABLES.BACKEND_URL}/auth/admin/login`,
      auth,
    );
  }

  async saveAuthInVault(user: string | null): Promise<void> {
    if (user) this.storage.saveValueInStorage('auth', user);
  }

  async signIn(auth: AuthRequestDTO): Promise<void> {
    try {
      const response = await this.signInWithCredentials(auth);

      const authData: AuthResponseDTO = response.data;

      authData.roles = authData.roles.map((rol, index) => {
        return {
          ...rol,
          active: index === 0 ? true : false,
        };
      });

      if (authData.mandatory_change) {
        // TODO add navigation to firstPasswrd
        // this.navigate("/FirstPassword", {
        //   state: {
        //     credentials: {
        //       email: auth.email,
        //       password: auth.password,
        //     },
        //     auth: response.data,
        //   },
        // });

        this.saveAuthInVault(encryptData(JSON.stringify(authData)));
        this.authInfo.setAuthState(authData);
        this.navigate('/neighborhood');
      } else {
        this.saveAuthInVault(encryptData(JSON.stringify(authData)));
        this.authInfo.setAuthState(authData);
        this.navigate('/neighborhood');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        throw new Error('Invalid credentials');
      } else {
        console.error(error);
        throw new Error('An unexpected error occurred');
      }
    }
  }
}
