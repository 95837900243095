import { useCallback, useEffect, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { VisitEntity } from '../entity/Visit.entity';
import { useReportService } from '../service/Report.service';
import { IUseVisitReport } from './types';

export const useReports = (): IUseVisitReport => {
  const { getReports } = useReportService();
  const [handleErrors] = useHandleErrors();
  const [reports, setReports] = useState<VisitEntity[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchReports = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getReports();
        setReports(response.data);
      },
      async (err) => {
        console.error('Error obteniendo el reporte de visitas:', err);
        setError('Hubo un error al obtener el reporte de visitas');
        setReports([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [getReports, handleErrors]);

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [reports, fetchReports, loading, error];
};
