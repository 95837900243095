import React from 'react';

import { PqrsTable } from '../../../feature/pqrs/templates/PqrsTable';

import styles from './styles.module.scss';

const PqrsTemplate = () => {
  return (
    <div className={styles.content}>
      <PqrsTable />
    </div>
  );
};

export default PqrsTemplate;
