import { NavArrowDown, NavArrowUp } from 'iconoir-react';

import React, { useState } from 'react';

import { Icon } from '../Icon';
import { LinkOrButton } from '../LinkOrButton';
import Text from '../Text';
import styles from './styles.module.scss';
import type { IDropdownProps } from './types';

const Dropdown: React.FC<IDropdownProps> = ({
  label,
  labelColor = 'black',
  options,
  selectedValue,
  onValueChange,
  error,
  iconColor = 'primary',
  filter = false,
  styleContainer,
  style,
  searchEnabled = false,
  button = false,
  buttonText = '+ Crear categoría',
  buttonAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelect = (value: string, index: number) => {
    onValueChange(value, index);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className={`${styles.container} ${styleContainer} `}>
      {label && (
        <div className={styles.label}>
          <Text text={label} textStyle={'B2-Medium'} color={labelColor} />
        </div>
      )}
      <div
        className={`${styles.dropdownButtonStyle} ${style} ${error ? styles.errorForm : ''}`}
        onClick={handleDropdownClick}
      >
        {searchEnabled && isOpen ? (
          <>
            <input
              type='text'
              value={searchTerm}
              onChange={handleSearchChange}
              autoFocus
              style={{
                flex: '1',
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontFamily: 'Space Grotesk, sans-serif',
                fontSize: '14px',
                fontWeight: '400',
                padding: '0',
              }}
            />
          </>
        ) : (
          <Text
            text={
              selectedValue
                ? options.find((option) => option.value === selectedValue)
                    ?.label ?? 'Seleccionar'
                : 'Seleccionar'
            }
            textStyle={'B2-Regular'}
            className={
              selectedValue ? styles.selectedTextStyle : styles.defaultTextStyle
            }
          />
        )}
        <Icon color={iconColor} width={filter ? 15 : undefined}>
          {isOpen ? <NavArrowUp /> : <NavArrowDown />}
        </Icon>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenuStyle}>
          <ul className={styles.dropOptions}>
            {filteredOptions.map((option, index) => (
              <li
                key={option.value}
                className={`${styles.dropdownItemStyle} ${hoveredIndex === index ? styles.hoveredItemStyle : ''} ${selectedValue === option.value ? styles.selectedItemStyle : ''}`}
                onClick={() => handleSelect(option.value, index)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Text
                  text={option.label}
                  textStyle={'B2-Regular'}
                  color='black'
                  className={
                    selectedValue === option.value ? styles.textSelected : ''
                  }
                />
              </li>
            ))}
          </ul>
          {button && (
            <ul className={`${styles.dropOptions} ${styles.dropButton}`}>
              <LinkOrButton
                text={buttonText}
                textStyle='B2-Regular'
                color='black'
                onClick={buttonAction}
                className={styles.addCategoryButton}
              />
            </ul>
          )}
        </div>
      )}
      {error && (
        <Text
          text={error}
          textStyle={'B2-Medium'}
          className={styles.errorMessage}
        />
      )}
    </div>
  );
};

export default Dropdown;
