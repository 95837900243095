import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { AddCategoryNotificationDTO } from '../dto/AddCategoryNotification.dto';
import { AddNotificationForBlocksDTO } from '../dto/AddNotificationForBlocks.dto';
import { AddNotificationForNeighborhoodDTO } from '../dto/AddNotificationForNeighborhood.dto';
import { AddNotificationForResidencesDTO } from '../dto/AddNotificationForResidences.dto';
import { NotificationUseCase } from '../usecase/Notification.usecase';

export const useNotificationService = (): NotificationUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getNotifications = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get(`/admin/notifications`);
  }, [axiosInstance]);

  const getNotificationsCategory =
    useCallback(async (): Promise<AxiosResponse> => {
      return await axiosInstance().get(`/admin/notifications/categories`);
    }, [axiosInstance]);

  const addNeighborhoodNotification = useCallback(
    async (data: AddNotificationForNeighborhoodDTO): Promise<AxiosResponse> => {
      return await axiosInstance().post(
        `/admin/notifications/neighborhood`,
        data,
      );
    },
    [axiosInstance],
  );

  const addResidencesNotification = useCallback(
    async (data: AddNotificationForResidencesDTO): Promise<AxiosResponse> => {
      return await axiosInstance().post(`/admin/notifications/homes`, data);
    },
    [axiosInstance],
  );

  const addBlocksNotification = useCallback(
    async (data: AddNotificationForBlocksDTO): Promise<AxiosResponse> => {
      return await axiosInstance().post(`/admin/notifications/blocks`, data);
    },
    [axiosInstance],
  );

  const addCategoryNotification = useCallback(
    async (data: AddCategoryNotificationDTO): Promise<AxiosResponse> => {
      return await axiosInstance().post(
        `/admin/notifications/categories`,
        data,
      );
    },
    [axiosInstance],
  );

  return {
    getNotifications,
    getNotificationsCategory,
    addNeighborhoodNotification,
    addResidencesNotification,
    addBlocksNotification,
    addCategoryNotification,
  };
};
