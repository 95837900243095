import { Plus, Trash } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import { Button } from '../../../ui/atoms/Button';
import Dropdown from '../../../ui/atoms/Dropdown';
import { Icon } from '../../../ui/atoms/Icon';
import { Image } from '../../../ui/atoms/Image';
import { Input } from '../../../ui/atoms/Input';
import Text from '../../../ui/atoms/Text';
import { ToggleSwitch } from '../../../ui/atoms/ToggleSwitch';

import { Modal } from '../../../ui/molecules/Modal';
import { Popup } from '../../../ui/molecules/Popup';

import { GroupingNames } from '../../../neighborhood/core/enums/GroupingNames.enum';
import { GroupingType } from '../../../neighborhood/core/enums/GroupingType.enum';
import { useCreateHome } from '../../../neighborhood/core/hooks/AddResidence.hook';
import { BlockEntity } from '../../core/entity/Block.entity';
import styles from './styles.module.scss';

interface AddResidencePopupProps {
  isOpen: boolean;
  onClose: () => void;
  blocksList: BlockEntity[];
  groupingType: string;
}

const AddResidencePopup: React.FC<AddResidencePopupProps> = ({
  isOpen,
  onClose,
  blocksList,
  groupingType,
}) => {
  const [formData, setFormData] = useState({
    homeName: '',
    selectedBlock: '',
    squareMeter: '',
  });
  const [errors, setErrors] = useState({
    homeName: '',
    selectedBlock: '',
    squareMeter: '',
  });
  const [hasParking, setHasParking] = useState(false);
  const [parkingLots, setParkingLots] = useState(['']);
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);
  const [handleCreateResidence, createLoading, successCreation] =
    useCreateHome();
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const preferredName =
    GroupingNames[groupingType.toUpperCase() as keyof typeof GroupingNames] ||
    '';

  const handleChange = (field: string, value: string | number) => {
    const isNumeric = /^[0-9]*\.?[0-9]*$/.test(value.toString());
    setFormData({ ...formData, [field]: value });
    if (field === 'squareMeter' && !isNumeric) {
      setFormData({ ...formData, [field]: '' });
      setErrors({ ...errors, [field]: 'Este campo debe ser numérico' });
    } else {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const vacateFields = () => {
    setFormData({
      homeName: '',
      selectedBlock: '',
      squareMeter: '',
    });
    setErrors({
      homeName: '',
      selectedBlock: '',
      squareMeter: '',
    });
    setParkingLots(['']);
    setHasParking(false);
  };

  const blockOptions =
    blocksList?.map((block) => ({
      label: block.name,
      value: block.id.toString(),
    })) || [];

  const handleAddHome = async () => {
    const newErrors = {
      homeName: formData.homeName ? '' : 'Este campo es requerido',
      selectedBlock: formData.selectedBlock ? '' : 'Este campo es requerido',
      squareMeter: formData.squareMeter ? '' : 'Este campo es requerido',
    };

    setErrors(newErrors);

    if (
      !formData.homeName ||
      (!formData.selectedBlock && groupingType !== GroupingType.NINGUNA) ||
      !formData.squareMeter
    ) {
      return;
    }

    const data = {
      block:
        groupingType === GroupingType.NINGUNA
          ? blocksList[0].id
          : parseInt(formData.selectedBlock),
      name: formData.homeName,
      meter: parseFloat(formData.squareMeter),
      parking_lots: hasParking
        ? parkingLots.filter((lot) => lot.trim() !== '')
        : [],
    };
    await handleCreateResidence(data);
    onClose();
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    window.location.reload();
  };

  const handleParkingChange = (index: number, value: string) => {
    const updatedParkingLots = [...parkingLots];
    updatedParkingLots[index] = value;
    setParkingLots(updatedParkingLots);
  };

  const addParkingLot = () => {
    setParkingLots([...parkingLots, '']);
  };

  const removeParkingLot = (index: number) => {
    const updatedParkingLots = parkingLots.filter((_, i) => i !== index);
    setParkingLots(updatedParkingLots);
  };

  useEffect(() => {
    if (blocksList && blocksList.length > 0 && isOpen) {
      setFormData((prev) => ({
        ...prev,
        selectedBlock:
          groupingType === GroupingType.NINGUNA
            ? blocksList[0].id.toString()
            : '',
      }));
    }
  }, [isOpen, blocksList, groupingType]);

  useEffect(() => {
    if (successCreation) {
      setIsSuccessPopupOpen(true);
      vacateFields();
    }
  }, [successCreation]);

  useEffect(() => {
    if (isOpen) {
      vacateFields();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsTransitionComplete(true);
      }, 500);
    }
  }, [isOpen]);

  const toggleParkingColumn = () => {
    setHasParking((prev) => !prev);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        showCloseIcon={false}
        styleOverride={styles.titlePopup}
        isLoading={createLoading}
      >
        <div className={styles.formContainer}>
          <div className={styles.leftColumn}>
            <div className={styles.topFormContainer}>
              {groupingType !== GroupingType.NINGUNA &&
                blockOptions.length > 0 && (
                  <Dropdown
                    label={`* Seleccionar ${preferredName}`}
                    options={blockOptions}
                    selectedValue={formData.selectedBlock}
                    onValueChange={(value) =>
                      handleChange('selectedBlock', value)
                    }
                    searchEnabled={true}
                    error={errors.selectedBlock}
                    labelColor='gray'
                  />
                )}
              <Input
                label='* Nombre residencia'
                modernStyle
                value={formData.homeName}
                onChangeText={(value) => handleChange('homeName', value)}
                error={errors.homeName}
                labelTextColor='gray'
                maxLength={25}
              />
            </div>
            <div className={styles.bottomFormContainer}>
              <Input
                label='* Metraje residencia'
                modernStyle
                value={formData.squareMeter}
                onChangeText={(value) =>
                  handleChange('squareMeter', parseFloat(value))
                }
                error={errors.squareMeter}
                inputMode='decimal'
                labelTextColor='gray'
                maxLength={4}
                style={styles.squareMeterInput}
              />
              <Text
                text='metros'
                textStyle={'B1-Medium'}
                className={styles.squareMeterText}
              >
                <span>
                  metros<sup>2</sup>
                </span>
              </Text>
            </div>
            <div className={styles.parkingLotQuestionContainer}>
              <Image srcName={'car'} width={38} height={38} />
              <ToggleSwitch
                text='¿Cuenta con parqueadero?'
                checked={hasParking}
                onChange={toggleParkingColumn}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                text={'Cerrar'}
                onPress={onClose}
                cancelButton
                closeButton
              />
              <Button text={'Agregar'} onPress={handleAddHome} changeButton />
            </div>
          </div>
          <div
            className={`${styles.rightColumn} ${
              hasParking && isTransitionComplete ? styles.active : ''
            }`}
          >
            {isTransitionComplete && hasParking && (
              <div className={styles.parkingLotsListContainer}>
                {parkingLots.map((lot, index) => (
                  <div key={index} className={styles.parkingLotsListItem}>
                    <Input
                      label={`Identificador parqueadero ${index + 1}`}
                      value={lot}
                      onChangeText={(value) =>
                        handleParkingChange(index, value)
                      }
                      labelTextColor='gray'
                      inputStyle={styles.parkingInput}
                      maxLength={5}
                      style={styles.parkingInputContainer}
                      modernStyle
                    />
                    {index === parkingLots.length - 1 && (
                      <Icon
                        width={24}
                        height={24}
                        color='primary'
                        onClick={addParkingLot}
                      >
                        <Plus />
                      </Icon>
                    )}
                    {index !== parkingLots.length - 1 && (
                      <Icon
                        width={20}
                        height={20}
                        color='primary'
                        onClick={() => removeParkingLot(index)}
                      >
                        <Trash />
                      </Icon>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Residencia creada con éxito!'
        buttons={false}
      />
    </>
  );
};

export default AddResidencePopup;
