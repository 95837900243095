import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { useResidentService } from '../service/Residents.service';

export const useRejectResidentRequest = (): [
  (id: number) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { rejectResidentsRequests } = useResidentService();
  const [handleErrors] = useHandleErrors();
  const [loadingRejection, setLoadingRejection] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleRejectRequest = async (id: number) => {
    setLoadingRejection(true);
    setError(null);
    setSuccess(false);

    await handleErrors(
      async () => {
        await rejectResidentsRequests(id);
        setSuccess(true);
      },
      async (err) => {
        console.error('Error rechazando la solicitud:', err);
        setError('Hubo un error al rechazar la solicitud');
      },
      () => {
        setLoadingRejection(false);
      },
    );
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleRejectRequest, loadingRejection, success, error];
};
