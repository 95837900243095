import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { Attachment, Edit, MediaImage } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import { Button } from '../../ui/atoms/Button';
import { Icon } from '../../ui/atoms/Icon';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';
import Text from '../../ui/atoms/Text';

import { ListEmpty } from '../../ui/molecules/ListEmpty';

import { NewsEntity } from '../core/entity/News.entity';
import { useNews } from '../core/hooks/GetNews.hook';
import { NewsDetails } from './NewsDetails';
import { NewsForm } from './NewsForm';
import styles from './styles.module.scss';

const NewsList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchNews, news, loading, error] = useNews();
  const [filteredNews, setFilteredNews] = useState<NewsEntity[]>([]);
  const [selectedNews, setSelectedNews] = useState<NewsEntity | null>(null);
  const [isCreatingNews, setIsCreatingNews] = useState(false);

  const handleFetchNews = () => {
    Promise.resolve(fetchNews());
  };

  useEffect(() => {
    handleFetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (news) {
      setFilteredNews(
        news.filter(
          (item) =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.category.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }
  }, [searchTerm, news]);

  const handleNewsClick = (newsItem: NewsEntity) => {
    setSelectedNews(newsItem);
    setIsCreatingNews(false);
  };

  const handleCreateNews = () => {
    setSelectedNews(null);
    setIsCreatingNews(true);
  };

  return (
    <div
      className={`${styles.container} ${isCreatingNews ? styles.createMode : ''}`}
    >
      <div className={styles.leftSection}>
        <div className={styles.topContainer}>
          <div className={styles.searchContainer}>
            <SearchInput
              placeholder='Buscar por título o categoría'
              value={searchTerm}
              onChangeText={setSearchTerm}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              text='Nueva noticia'
              style={styles.newNewsButton}
              icon={
                <Icon width={15} height={15}>
                  <Edit />
                </Icon>
              }
              cancelButton
              onPress={handleCreateNews}
            />
          </div>
        </div>
        <div className={styles.newsList}>
          {loading ? (
            <Loading size='small' backgroundColor={true} />
          ) : error ? (
            <div>Error: {error}</div>
          ) : filteredNews.length > 0 ? (
            <ul className={styles.newsList}>
              {filteredNews.map((newsItem) => (
                <li
                  key={newsItem.id}
                  className={styles.newsItem}
                  onClick={() => handleNewsClick(newsItem)}
                >
                  <div className={styles.newsCard}>
                    <div className={styles.newsCardContent}>
                      <Text text={newsItem.title} textStyle='B1-Medium' />
                      <Text
                        text={newsItem.description}
                        textStyle='B1-Regular'
                        className={styles.truncate}
                      />
                    </div>
                    <div className={styles.newsCardInfo}>
                      <Text
                        text={format(
                          parseISO(newsItem.creation_date),
                          'dd-MMMM',
                          { locale: es },
                        )}
                        textStyle='B2-Medium'
                      />
                      <div>
                        {newsItem.image && (
                          <Icon color='tertiary3' width={15} height={15}>
                            <MediaImage />
                          </Icon>
                        )}
                        {newsItem.details && (
                          <Icon color='tertiary3' width={15} height={15}>
                            <Attachment />
                          </Icon>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <ListEmpty />
          )}
        </div>
      </div>
      <div className={styles.rightSection}>
        {isCreatingNews ? (
          <NewsForm />
        ) : selectedNews ? (
          <NewsDetails news={selectedNews} />
        ) : (
          <div className={styles.noNewsSelected}>
            <ListEmpty
              header={'Selecciona un elemento para leerlo'}
              imageSrc='resiTip'
              width={208.14}
              height={178.035}
              title={'No hay nada seleccionado'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { NewsList };
