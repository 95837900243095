import { NavArrowDown } from 'iconoir-react';

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { SidebarItemProps } from './types';

const SidebarItem: React.FC<SidebarItemProps> = ({
  id,
  label,
  icon,
  path,
  isActive,
  onClick,
  subItems,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleItemClick = () => {
    if (subItems && subItems.length > 0) {
      setIsOpen(!isOpen);
    } else {
      onClick(id);
    }
  };

  const isSubItemActive = (subItemPath: string) =>
    location.pathname === subItemPath;
  const isAnySubItemActive = subItems?.some((subItem) =>
    isSubItemActive(subItem.path),
  );

  return (
    <>
      <Link
        to={path}
        className={styles.link}
        style={{ textDecoration: 'none' }}
      >
        <li
          key={id}
          className={`${styles.menuItem} ${isActive ? styles.active : ''} ${
            subItems && subItems.length > 0
              ? styles.hasSubItems
              : styles.noSubItems
          }`}
          onClick={handleItemClick}
        >
          <Icon
            color={isActive || isAnySubItemActive ? 'primary' : 'secondary2'}
            strokeWidth={1.5}
            width={25}
            height={25}
          >
            {icon}
          </Icon>
          <Text
            text={label}
            textStyle='B1-Regular'
            color={isActive || isAnySubItemActive ? 'primary' : 'secondary2'}
          />
          {subItems && subItems.length > 0 && (
            <NavArrowDown
              color={isActive || isAnySubItemActive ? 'primary' : 'secondary2'}
              strokeWidth={1.5}
              width={20}
              height={20}
              className={`${styles.dropdownIcon} ${isOpen ? styles.open : ''}`}
            />
          )}
        </li>
      </Link>
      {isOpen && subItems && (
        <ul className={styles.subMenu}>
          {subItems.map((subItem) => (
            <Link
              key={subItem.id}
              to={subItem.path}
              className={styles.link}
              style={{ textDecoration: 'none' }}
            >
              <li
                key={subItem.id}
                className={`${styles.subMenuItem} ${isSubItemActive(subItem.path) ? styles.subActive : ''}`}
                onClick={() => onClick(subItem.id)}
              >
                <Icon
                  color={
                    isSubItemActive(subItem.path) || isAnySubItemActive
                      ? 'primary'
                      : 'secondary2'
                  }
                  strokeWidth={1.5}
                  width={20}
                  height={20}
                >
                  {subItem.icon}
                </Icon>
                <Text
                  text={subItem.label}
                  textStyle='B1-Regular'
                  color={
                    isSubItemActive(subItem.path) || isAnySubItemActive
                      ? 'primary'
                      : 'secondary2'
                  }
                />
              </li>
            </Link>
          ))}
        </ul>
      )}
    </>
  );
};

export default SidebarItem;
