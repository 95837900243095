import React from 'react';

import { NewsList } from '../../../feature/news/templates/NewsList';

import styles from './styles.module.scss';

const NewsTemplate = () => {
  return (
    <div className={styles.content}>
      <NewsList />
    </div>
  );
};

export default NewsTemplate;
