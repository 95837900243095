import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../ui/atoms/Button';
import { Image } from '../../../ui/atoms/Image';
import Text from '../../../ui/atoms/Text';

import styles from './styles.module.scss';

const CheckEmailTemplate = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Image
          srcName='resiredLogo'
          width={200}
          minWidth={190}
          height={'auto'}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.image}>
            <Image srcName='resiAnounce' width={317.3} height={162.6} />
          </div>
          <div className={styles.info}>
            <Text text='Revisa tu correo electrónico' textStyle='SH1-Bold' />
            <Text
              text='Si el correo ingresado anteriormente es válido, en tu correo encontrarás las instrucciones para cambiar tu contraseña.'
              textStyle='B1-Regular'
            />
            <div className={styles.containerButton}>
              <Button
                text='Volver al inicio'
                onPress={() => navigate('/')}
                changeButton
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CheckEmailTemplate };
