import { MoreHoriz } from 'iconoir-react';

import React, { useState } from 'react';

import { DropMenu } from '../../atoms/DropMenu';
import { Icon } from '../../atoms/Icon';
import { Input } from '../../atoms/Input';
import { Loading } from '../../atoms/Loading';
import Text from '../../atoms/Text';

import { ListEmpty } from '../../molecules/ListEmpty';
import { Modal } from '../../molecules/Modal';
import { Popup } from '../../molecules/Popup';

import { PopupTable } from '../../organisms/Table/PopupTable';

import { useResidents } from '../../../neighborhood/core/hooks/GetResidents.hook';
import { useVacateHome } from '../../../neighborhood/core/hooks/RemoveResidents.hook';
import { useUpdateHomeName } from '../../../neighborhood/core/hooks/UpdateHomeName.hook';
import styles from './styles.module.scss';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({
  id,
  name,
  block,
  residents,
  isMenuOpen,
  onMenuToggle,
}) => {
  const [isResidentsModalOpen, setResidentsModalOpen] = useState(false);
  const [isUpdateNamePopupOpen, setUpdateNamePopupOpen] = useState(false);
  const [isVacatePopupOpen, setVacatePopupOpen] = useState(false);
  const [newName, setNewName] = useState(name);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateSuccessPopupOpen, setUpdateSuccessPopupOpen] = useState(false);
  const [isVacateSuccessPopupOpen, setVacateSuccessPopupOpen] = useState(false);

  const [handleVacate] = useVacateHome();
  const [fetchResidents, residentList] = useResidents();
  const [handleUpdateHomeName] = useUpdateHomeName();

  const handleViewResidents = () => {
    setIsLoading(true);
    Promise.resolve(fetchResidents(id))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
    setResidentsModalOpen(true);
  };

  const handleUpdateName = () => {
    setUpdateNamePopupOpen(true);
  };

  const handleConfirmUpdateName = () => {
    handleUpdateHomeName(id, newName);
    setUpdateNamePopupOpen(false);
    setUpdateSuccessPopupOpen(true);
  };

  const vacate = () => {
    handleVacate(id);
    setVacatePopupOpen(false);
    setVacateSuccessPopupOpen(true);
  };

  const menuItems = [
    { label: 'Ver residentes', onClick: handleViewResidents },
    { label: 'Actualizar nombre', onClick: handleUpdateName },
    {
      label: 'Desocupar',
      onClick: () => setVacatePopupOpen(true),
    },
  ];

  const columns = [
    { title: 'Nombre completo', sortable: false, type: 'string' as const },
    { title: 'Email', sortable: false, type: 'string' as const },
  ];

  const data = residentList?.length
    ? residentList.map((resident) => [
        { text: resident.first_name + ' ' + resident.last_name },
        { text: resident.email },
      ])
    : [];

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.name}>
            <Text
              text='Apartamento/Casa'
              textStyle='B2-Regular'
              color='tertiary4'
            />
            <Text text={name} textStyle='SH1-Bold' color='tertiary4' />
          </div>
          <div className={styles.iconContainer} onClick={onMenuToggle}>
            <Icon color='tertiary4'>
              <MoreHoriz />
            </Icon>
          </div>
          <DropMenu
            items={menuItems}
            isOpen={isMenuOpen}
            customClass={styles.customDropMenuPosition}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.footerItem}>
            <Text text={block} textStyle='B1-Bold' color='tertiary4' />
          </div>
          <div className={styles.footerItem}>
            <Text text='RESIDENTES' textStyle='B2-Regular' color='tertiary4' />
            <Text
              text={residents.toString()}
              textStyle='B1-Bold'
              color='tertiary4'
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isResidentsModalOpen}
        onRequestClose={() => setResidentsModalOpen(false)}
        title={`${block} ${' - '}${name}`}
      >
        <div>
          {isLoading ? (
            <Loading backgroundColor={false} size='medium' type='bars' />
          ) : data.length > 0 ? (
            <PopupTable columns={columns} data={data} imageSrc={'resident'} />
          ) : (
            <ListEmpty
              title='No hay residentes registrados'
              message='Registra residentes en la pestaña “Mis residentes”'
              width={130.6}
              height={144.3}
            />
          )}
        </div>
      </Modal>

      <Popup
        isOpen={isUpdateNamePopupOpen}
        onClose={() => setUpdateNamePopupOpen(false)}
        imageSrc={'resiSurprised'}
        titleText='¿Quieres cambiar el nombre de esta residencia?'
        messageText='Registra el nuevo nombre de la residencia'
        whiteText='Cancelar'
        redText='Actualizar'
        buttons={true}
        onConfirmation={handleConfirmUpdateName}
      >
        <Input
          placeholder='Nuevo nombre'
          label='Nombre de la residencia *'
          labelTextColor='gray'
          value={newName}
          onChangeText={setNewName}
          modernStyle={true}
          maxLength={25}
          style={styles.input}
        />
      </Popup>

      <Popup
        isOpen={isVacatePopupOpen}
        onClose={() => setVacatePopupOpen(false)}
        imageSrc={'resiBags'}
        titleText='Desocupar'
        messageText='¿Está seguro que desea desocupar esta residencia?'
        whiteText='Cancelar'
        redText='Desocupar'
        buttons={true}
        onConfirmation={vacate}
      />

      <Popup
        isOpen={isUpdateSuccessPopupOpen}
        onClose={() => setUpdateSuccessPopupOpen(false)}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Nombre de la residencia cambiado con éxito!'
        buttons={false}
      />

      <Popup
        isOpen={isVacateSuccessPopupOpen}
        onClose={() => setVacateSuccessPopupOpen(false)}
        imageSrc={'resiLook'}
        width={180}
        titleText='¡Residencia desocupada exitosamente!'
        buttons={false}
      />
    </div>
  );
};

export { Card };
