import { NavArrowDown } from 'iconoir-react';

import React, { useState } from 'react';

import { DropMenu } from '../../atoms/DropMenu';
import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { ITopBarProps } from './types';

const TopBar: React.FC<ITopBarProps> = ({ title, username, toggleItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const neighborhoodName = /* authState?.roles[0].neighborhood_name */ '';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className={styles.container}>
        <Text
          text={neighborhoodName ? neighborhoodName + ' - ' + title : title}
          textStyle={'SH1-Bold'}
          className={styles.title}
          color='primary'
        />
        <div className={styles.userContainer}>
          <div onClick={toggleMenu}>
            <Text text={username} textStyle={'SH2-Regular'} />
            <div
              className={`${styles.icon} ${isMenuOpen ? styles.iconOpen : ''}`}
            >
              <Icon color={'black'} width={24} height={24} strokeWidth={1.5}>
                <NavArrowDown />
              </Icon>
            </div>
          </div>
          <div className={styles.menu}>
            <DropMenu items={toggleItems} isOpen={isMenuOpen} />
          </div>
        </div>
      </div>
    </>
  );
};

export { TopBar };
