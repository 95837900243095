import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { useGuardsService } from '../service/Guards.service';

export const useCreateGuard = (): [
  (data: {
    document_id: string;
    document_type: string;
    first_name: string;
    last_name: string;
    email: string;
  }) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { createGuard } = useGuardsService();
  const [handleErrors] = useHandleErrors();
  const [loadingCreateGuard, setLoadingCreateGuard] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleCreateGuard = useCallback(
    async (data: {
      document_id: string;
      document_type: string;
      first_name: string;
      last_name: string;
      email: string;
    }) => {
      setLoadingCreateGuard(true);
      setError(null);
      setSuccessCreation(false);
      await handleErrors(
        async () => {
          await createGuard(data);
          setSuccessCreation(true);
        },
        async (err) => {
          console.error('Error creando el guarda:', err);
          setError('Hubo un error al crear el guarda');
          setSuccessCreation(false);
        },
        () => {
          setLoadingCreateGuard(false);
        },
      );
    },
    [createGuard, handleErrors],
  );

  return [handleCreateGuard, loadingCreateGuard, successCreation, error];
};
