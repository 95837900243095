import { Upload } from 'iconoir-react';

import React from 'react';
import { toast } from 'react-toastify';

import { Icon } from '../Icon';
import Text from '../Text';
import styles from './styles.module.scss';
import { IInputFileProps } from './types';

const InputFile = ({
  label,
  placeholder = 'Seleccionar archivo...',
  maxFiles = 3,
  allowMultiple = false,
  files,
  setFileList,
}: IInputFileProps) => {
  const handleOnSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file && file.length > 0) {
      if (allowMultiple && file.length > maxFiles) {
        toast.error(`Se permiten máximo ${maxFiles} archivos.`);
        return;
      }

      setFileList(file);
    }
  };

  return (
    <div className={styles.container}>
      {label && (
        <Text text={label} textStyle='B2-Medium' className={styles.label} />
      )}
      <div className={styles.boxContainer}>
        <input
          type='file'
          multiple={allowMultiple}
          onChange={handleOnSelectFile}
        />
        <div className={`${styles.inputLabel} ${files ? '' : styles.p4}`}>
          <Icon color={'neutral'}>
            <Upload />
          </Icon>
          {!files && (
            <Text text={placeholder} textStyle='B2-Medium' color={'neutral'} />
          )}
        </div>
        {files && (
          <div className={styles.fileLabel}>
            <Text
              text={
                files.length > 1
                  ? `${files.length} archivos seleccionados`
                  : files.item(0)!.name
              }
              textStyle='B2-Medium'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { InputFile };
