import { MessageText, SendDiagonal } from 'iconoir-react';

import React, { useState } from 'react';
import ShowMoreText from 'react-show-more-text';

import { Button } from '../../../ui/atoms/Button';
import { Icon } from '../../../ui/atoms/Icon';
import Text from '../../../ui/atoms/Text';

import { PqrsState } from '../../core/enums/PqrsState.enum';
import styles from './styles.module.scss';

interface ResponseCardProps {
  onSubmit: (ticket_number: string, response: string) => void;
  ticket_number: string;
  state: PqrsState;
  response: string | null;
  isLoading?: boolean;
}

const ResponseCard: React.FC<ResponseCardProps> = ({
  onSubmit,
  ticket_number,
  state,
  response,
  isLoading,
}) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!message.trim()) {
      setError('El mensaje no puede estar vacío');
      return;
    }

    onSubmit(ticket_number, message);
    setMessage('');
    setError('');
  };

  return (
    <div className={styles.responseBox}>
      <div className={styles.title}>
        <Icon color='primary'>
          <MessageText height={20} width={20} />
        </Icon>
        <Text
          text={state === PqrsState.EN_REVISION ? 'Responder' : 'Respuesta'}
          textStyle={'SH2-Bold'}
          color='primary'
        />
      </div>
      {state === PqrsState.COMPLETADA ? (
        <div className={styles.completedMessage}>
          <ShowMoreText
            lines={2}
            expanded={false}
            className={styles.response}
            more='Ver más'
            less='Ver menos'
            anchorClass={styles.responseShowMore}
          >
            <Text text={response || ''} textStyle={'B1-Regular'} />
          </ShowMoreText>
        </div>
      ) : (
        <>
          <textarea
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setError('');
            }}
            placeholder='Escribe tu mensaje...'
            className={
              error === ''
                ? styles.textarea
                : styles.textarea + ' ' + styles.errorBorder
            }
            maxLength={5000}
          />
          {error && (
            <Text text={error} textStyle={'B2-Medium'} color='primary' />
          )}
          <Button
            text='Enviar'
            textStyle='B2-Medium'
            onPress={handleSubmit}
            icon={
              <Icon color='neutral' height={20} width={20}>
                <SendDiagonal />
              </Icon>
            }
            cancelButton
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export { ResponseCard };
