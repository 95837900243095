import { NavArrowDown, NavArrowUp } from 'iconoir-react';

import React from 'react';

import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { ITableHeaderProps } from './types';

const TableHeader: React.FC<ITableHeaderProps> = ({
  columns,
  onSort,
  sortOrder,
}) => {
  return (
    <thead>
      <tr>
        <th></th>
        {columns.map((column, index) => (
          <th
            key={index}
            onClick={() => column.sortable && onSort(column.title)}
            className={styles.headerCell}
          >
            <div className={styles.headerContent}>
              <div>
                <Text text={column.title} textStyle='B1-Medium' />
              </div>
              <div>
                {column.sortable && (
                  <Icon width={16} height={16} color='black'>
                    {sortOrder[column.title] === 'asc' ? (
                      <NavArrowUp />
                    ) : (
                      <NavArrowDown />
                    )}
                  </Icon>
                )}
              </div>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export { TableHeader };
