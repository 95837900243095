import { StorageKeys, VaultUseCase } from './type';

export class LocalStorageVault implements VaultUseCase {
  async saveValueInStorage(key: StorageKeys, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }
  async getValueFromStorage(key: StorageKeys): Promise<string | null> {
    const value = localStorage.getItem(key);
    return value ? value : null;
  }
  async resetStorage(key: StorageKeys): Promise<void> {
    localStorage.removeItem(key);
  }
}
