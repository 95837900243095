import { Search, Xmark } from 'iconoir-react';

import React from 'react';

import styles from './styles.module.scss';
import { ISearchInputProps } from './types';

const SearchInput = ({
  placeholder,
  keyboardType = 'default',
  value,
  onChangeText,
}: ISearchInputProps) => {
  return (
    <div className={styles.container}>
      <Search className={styles.icon} />
      <input
        type={keyboardType === 'default' ? 'text' : keyboardType}
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
        enterKeyHint={'search'}
      />
      <div className={styles.iconContainer}>
        {value.length > 0 && (
          <button
            className={styles.clearButton}
            onClick={() => onChangeText('')}
          >
            <Xmark className={styles.icon} />
          </button>
        )}
      </div>
    </div>
  );
};

export { SearchInput };
