import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { CreateNewsEntity } from '../entity/CreateNews.entity';
import { useNewsService } from '../service/News.service';
import { IUseCreateNews } from './types';

export const useCreateNews = (): IUseCreateNews => {
  const { createNews } = useNewsService();
  const [handleErrors] = useHandleErrors();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleCreateGuardNews = useCallback(
    async (data: CreateNewsEntity) => {
      setLoading(true);
      setError(null);
      setSuccessCreation(false);

      await handleErrors(
        async () => {
          await createNews(data);
          setSuccessCreation(true);
        },
        async (err) => {
          console.error('Error creando la noticia:', err);
          setError('Hubo un error al crear la noticia');
          setSuccessCreation(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [createNews, handleErrors],
  );

  return [handleCreateGuardNews, loading, successCreation, error];
};
