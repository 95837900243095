import { format, parseISO } from 'date-fns';
import { ChatBubbleWarning } from 'iconoir-react';

import React from 'react';

import { Icon } from '../../../ui/atoms/Icon';
import Text from '../../../ui/atoms/Text';

import styles from './styles.module.scss';
import { INotificationDetailsProps } from './types';

const NotificationDetails: React.FC<INotificationDetailsProps> = ({
  notification,
}) => {
  return (
    <div className={styles.notificationDetails}>
      <div className={styles.header}>
        <Text text={notification.title} textStyle='SH2-Bold' color='primary' />
        <div className={styles.data}>
          <Icon color='primary' height={13} width={13}>
            <ChatBubbleWarning />
          </Icon>
          <Text
            text={format(parseISO(notification.date), 'MMM dd yyyy')}
            textStyle='B2-Medium'
            color='black'
          />
        </div>
      </div>

      <div className={styles.description}>
        <Text text={notification.message} textStyle='B2-Regular' />
      </div>
    </div>
  );
};

export { NotificationDetails };
