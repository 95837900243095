import React from 'react';

import { TopBar } from '../../ui/molecules/TopBar';

import { TopbarHook } from '../core/hooks/TopbarHook';
import { ChangePasswordModal } from './ChangePassword';
import { NeighborhoodChangeModal } from './NeighborhoodChangeModal';
import { ITopBarTemplateProps } from './types';

const TopbarTemplate = ({ title }: ITopBarTemplateProps) => {
  const {
    getMenuItems,
    isNeighborhoobChange,
    isPasswordModalOpen,
    setIsNeighborhoobChange,
    setIsPasswordModalOpen,
    userName,
    neighborhoodName,
  } = TopbarHook();

  const getTitle = (): string => {
    return neighborhoodName ? `${neighborhoodName} - ${title}` : title;
  };

  return (
    <>
      <TopBar
        toggleItems={getMenuItems()}
        title={getTitle()}
        username={userName}
      />
      <ChangePasswordModal
        isModalOpen={isPasswordModalOpen}
        setIsModalOpen={setIsPasswordModalOpen}
      />
      <NeighborhoodChangeModal
        isModalOpen={isNeighborhoobChange}
        setIsModalOpen={setIsNeighborhoobChange}
      />
    </>
  );
};

export { TopbarTemplate };
