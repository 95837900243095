import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import React, { useState } from 'react';

import { Button } from '../../../ui/atoms/Button';
import { Input } from '../../../ui/atoms/Input';
import { Loading } from '../../../ui/atoms/Loading';
import Text from '../../../ui/atoms/Text';

import { Modal } from '../../../ui/molecules/Modal';
import { Popup } from '../../../ui/molecules/Popup';

import { useAuthContext } from '../../../authentication/core/context/Authentication.context';
import { ChangePassword } from '../../core/hooks/ChangePassword';
import styles from './styles.module.scss';
import { IInitialValuesForm } from './types';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const { authState } = useAuthContext();
  const { handleChangePassword, loading } = ChangePassword();

  const [completedChange, setCompletedChange] = useState(false);

  const initialValues: IInitialValuesForm = {
    email: authState!.user_email,
    password: '',
    newPassword: '',
  };

  const validationSchema = Yup.object<IInitialValuesForm>().shape({
    password: Yup.string().required('Contraseña requerida'),
    newPassword: Yup.string().required('Nueva contraseña requerida'),
  });

  const onSubmit = async (values: IInitialValuesForm) => {
    await handleChangePassword(
      values.email,
      values.password,
      values.newPassword,
      () => {
        handleCloseModal();
        setCompletedChange(true);
      },
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        showCloseIcon={false}
      >
        <Text
          text={'Cambiar contraseña'}
          textStyle={'SH1-Bold'}
          color='primary'
        />
        <div className={styles.spacing} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => {
            return (
              <Form>
                <Input
                  label={'Correo electrónico'}
                  value={values.email}
                  style={styles.input}
                  modernStyle
                  disabled
                />
                <Input
                  modernStyle
                  onChangeText={handleChange('password')}
                  label={'Contraseña actual'}
                  autoCapitalize={'none'}
                  placeholder={'*****************'}
                  onBlur={handleBlur('password')}
                  value={values.password}
                  secureTextEntry
                  error={touched.password ? errors.password : undefined}
                  style={styles.input}
                />
                <Input
                  onChangeText={handleChange('newPassword')}
                  label={'Nueva contraseña'}
                  autoCapitalize={'none'}
                  placeholder={'*****************'}
                  onBlur={handleBlur('newPassword')}
                  secureTextEntry
                  value={values.newPassword}
                  error={touched.newPassword ? errors.newPassword : undefined}
                  style={styles.input}
                  modernStyle
                />
                <div className={styles.containerButton}>
                  {loading ? (
                    <div className={styles.loaderContainer}>
                      <Loading size='small' backgroundColor={false} />
                    </div>
                  ) : (
                    <>
                      <div className={styles.buttons}>
                        <Button
                          text='Cerrar'
                          onPress={() => setIsModalOpen(false)}
                          popup
                          closeButton
                        />
                        <Button
                          text='Cambiar contraseña'
                          onPress={handleSubmit}
                          isLoading={loading}
                          changeButton
                          style={styles.button}
                          popup
                        />
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Popup
        isOpen={completedChange}
        onClose={() => setCompletedChange(false)}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Contraseña cambiada exitosamente!'
        buttons={false}
      />
    </>
  );
};

export { ChangePasswordModal };
