import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { GuardsUseCase } from '../usecase/Guards.usecase';

export const useGuardsService = (): GuardsUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getGuards = useCallback(
    async (active: boolean): Promise<AxiosResponse> => {
      return await axiosInstance().get(`/admin/guards?active=${active}`);
    },
    [axiosInstance],
  );

  const removeGuard = useCallback(
    async (id: number): Promise<AxiosResponse> => {
      return await axiosInstance().delete(`/admin/guard/${id}`);
    },
    [axiosInstance],
  );

  const reactivateGuard = useCallback(
    async (id: number): Promise<AxiosResponse> => {
      return await axiosInstance().post(`/admin/guard/${id}`);
    },
    [axiosInstance],
  );

  const createGuard = useCallback(
    async (data: {
      document_id: string;
      document_type: string;
      first_name: string;
      last_name: string;
      email: string;
    }): Promise<AxiosResponse> => {
      return await axiosInstance().post('/admin/guard', data);
    },
    [axiosInstance],
  );

  return {
    getGuards,
    removeGuard,
    createGuard,
    reactivateGuard,
  };
};
