import axios, { AxiosInstance } from 'axios';

import { useCallback } from 'react';

import { ENV_VARIABLES } from '../../config/env';
import { useAuthContext } from '../../feature/authentication/core/context/Authentication.context';

export const useAxiosInstance = () => {
  const { authState } = useAuthContext();

  const axiosInstance = useCallback((): AxiosInstance => {
    return axios.create({
      baseURL: ENV_VARIABLES.BACKEND_URL,
      headers: {
        Authorization: `Bearer ${authState?.access_token}`,
      },
    });
  }, [authState]);

  const axiosInstanceWithoutSession = useCallback((): AxiosInstance => {
    return axios.create({
      baseURL: ENV_VARIABLES.BACKEND_URL,
    });
  }, []);

  return { axiosInstance, axiosInstanceWithoutSession };
};
