import { useAxiosInstance } from '../../../../utils/axios';

import { PasswordUseCase } from '../usecase/Password.usecase';

export const PasswordService = (): PasswordUseCase => {
  const { axiosInstanceWithoutSession } = useAxiosInstance();

  const changePswd = async (
    email: string,
    oldPswd: string,
    newPswd: string,
  ) => {
    return await axiosInstanceWithoutSession().put(`/account/password`, {
      email,
      old: oldPswd,
      new: newPswd,
    });
  };

  const recoverPswd = async (email: string) => {
    return await axiosInstanceWithoutSession().post(
      `/account/password/recovery?email=${email}`,
    );
  };

  const resetPswd = async (token: string, email: string) => {
    return await axiosInstanceWithoutSession().post(`/account/password/reset`, {
      token: token,
      password: email,
    });
  };

  return { changePswd, recoverPswd, resetPswd };
};
