import { AxiosResponse } from 'axios';

import { useCallback } from 'react';

import { useAxiosInstance } from '../../../../utils/axios';

import { ReportUseCase } from '../usecase/Report.usecase';

export const useReportService = (): ReportUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getReports = useCallback(async (): Promise<AxiosResponse> => {
    return await axiosInstance().get('/admin/report/visits');
  }, [axiosInstance]);

  return {
    getReports,
  };
};
