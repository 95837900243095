import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '../entity/User.entity';

interface IAuth {
  authState: User | undefined;
  setAuthState: Dispatch<SetStateAction<User | undefined>>;
  logout: () => void;
}

const AuthContext = createContext<IAuth>({
  authState: undefined,
  setAuthState: () => {},
  logout: () => {},
});

const AuthProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [authState, setAuthState] = useState<User | undefined>(undefined);
  const navigate = useNavigate();

  const logout = () => {
    setAuthState(undefined);
    localStorage.removeItem('auth');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = (): IAuth => useContext(AuthContext);

export { AuthProvider, useAuthContext };
