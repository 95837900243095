import React, { useEffect, useState } from 'react';

import { TableHeader } from '../../../molecules/TableHeader';
import { TableRow } from '../../../molecules/TableRow';

import { ITableProps } from '../types';
import styles from './styles.module.scss';

const PopupTable: React.FC<ITableProps> = ({
  columns,
  data,
  imageSrc,
  firstIcon,
  secondIcon,
  firstAction,
  secondAction,
}) => {
  const [sortOrder, setSortOrder] = useState<{ [key: string]: 'asc' | 'desc' }>(
    {},
  );
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = (column: string) => {
    const newOrder = sortOrder[column] === 'asc' ? 'desc' : 'asc';
    setSortOrder({ [column]: newOrder });

    const columnIndex = columns.findIndex((col) => col.title === column);

    const sorted = [...sortedData].sort((a, b) => {
      const aValue = a[columnIndex]?.text ?? '';
      const bValue = b[columnIndex]?.text ?? '';

      if (columns[columnIndex].type === 'number') {
        const aNum = aValue === '' ? Infinity : parseFloat(aValue);
        const bNum = bValue === '' ? Infinity : parseFloat(bValue);

        if (aNum < bNum) return newOrder === 'asc' ? -1 : 1;
        if (aNum > bNum) return newOrder === 'asc' ? 1 : -1;
        return 0;
      } else {
        if (aValue < bValue) return newOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return newOrder === 'asc' ? 1 : -1;
        return 0;
      }
    });

    setSortedData(sorted);
  };

  return (
    <div className={styles.tableContainer}>
      {sortedData.length > 0 && (
        <table className={styles.table}>
          <TableHeader
            columns={columns}
            onSort={handleSort}
            sortOrder={sortOrder}
          />
          <tbody>
            {sortedData.map((row, index) => (
              <TableRow
                key={index}
                rowIndex={index}
                rowData={row}
                imageSrc={imageSrc}
                firstIcon={firstIcon}
                secondIcon={secondIcon}
                firstAction={firstAction}
                secondAction={secondAction}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export { PopupTable };
