import React from 'react';

import { ReportVisitTable } from '../../../../feature/report/templates/ReportVisitsTable';

import styles from './styles.module.scss';

const VisitsTemplate = () => {
  return (
    <div className={styles.content}>
      <ReportVisitTable />
    </div>
  );
};

export default VisitsTemplate;
