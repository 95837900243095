import React from 'react';

import Text from '../../../../feature/ui/atoms/Text';

import styles from './styles.module.scss';

const GuardsCompanyTemplate = () => {
  return (
    <div className={styles.content}>
      <Text
        text='Aquí va la página de Empresa de seguridad'
        textStyle='H2-Bold'
      />
    </div>
  );
};

export default GuardsCompanyTemplate;
