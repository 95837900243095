import React, { useState } from 'react';

import { Loading } from '../../ui/atoms/Loading';
import Text from '../../ui/atoms/Text';

import { Card } from '../../ui/molecules/Card';
import { ListEmpty } from '../../ui/molecules/ListEmpty';

import styles from './styles.module.scss';
import { IHomeCardProps } from './types';

interface ISeparator {
  blockTitle: string;
  cards: React.ReactNode[];
}

export const HomeCards: React.FC<IHomeCardProps> = ({ homes }) => {
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);

  const handleMenuToggle = (id: number) => {
    setOpenMenuId((prevOpenMenuId) => (prevOpenMenuId === id ? null : id));
  };

  if (!homes) {
    return <Loading size='small' backgroundColor={true} />;
  }

  if (homes.length === 0) {
    return <ListEmpty />;
  }

  const getCards = () => {
    const result = homes.reduce<ISeparator[]>((acc, home) => {
      const index = acc.findIndex((separator) => {
        return separator.blockTitle === home.block;
      });

      if (index === -1) {
        acc.push({
          blockTitle: home.block,
          cards: [],
        });
      }

      acc[index === -1 ? acc.length - 1 : index].cards.push(
        <Card
          key={`${home.id}_${home.block}`}
          id={home.id}
          name={home.name}
          block={home.block}
          residents={home.residents}
          isMenuOpen={openMenuId === home.id}
          onMenuToggle={() => handleMenuToggle(home.id)}
        />,
      );

      return acc;
    }, []);

    return result.map((separator, index) => {
      return (
        <div key={`${index}-${separator.blockTitle}_card-container`}>
          <div
            style={{
              margin: '10px 5px',
              width: 'fit-content',
            }}
          >
            <Text text={separator.blockTitle} textStyle={'SH2-Bold'} />
          </div>
          <div className={styles.homesContainer}>
            {separator.cards.map((card) => card)}
          </div>
        </div>
      );
    });
  };

  return <div className={styles.main}>{getCards()}</div>;
};
