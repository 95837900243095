import React, { useContext } from 'react';

import { TopbarTemplate } from '../../../feature/topbar/templates/TopbarTemplate';

import { SidebarContext } from '../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import styles from './styles.module.scss';

const Layout: React.FC<{ title: string; page: React.ReactNode }> = ({
  title,
  page,
}) => {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div
      className={`${styles.container} ${!isSidebarOpen ? styles.closed : ''}`}
    >
      <TopbarTemplate title={title} />
      {page}
    </div>
  );
};

export default Layout;
