import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { NotificationEntity } from '../entity/Notification.entity';
import { useNotificationService } from '../service/Notification.service';
import { IUseGetNotifications } from './types';

export const useNotifications = (): IUseGetNotifications => {
  const { getNotifications } = useNotificationService();
  const [handleErrors] = useHandleErrors();
  const [notifications, setNotifications] = useState<
    NotificationEntity[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getNotifications();
        setNotifications(response.data);
      },
      async (err) => {
        console.error('Error obteniendo las notificaciones:', err);
        setError('Hubo un error al obtener las notificaciones');
        setNotifications([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [getNotifications, handleErrors]);

  return [fetchNotifications, notifications, loading, error];
};
