import { useCallback, useState } from 'react';

import { useHandleErrors } from '../../../../utils/errors';

import { HomeNameEntity } from '../entity/HomeName.entity';
import { useResidentService } from '../service/Residents.service';

export const useGetHomesNames = (): [
  () => void,
  HomeNameEntity[] | null,
  boolean,
  string | null,
] => {
  const { getHomesNames } = useResidentService();
  const [handleErrors] = useHandleErrors();
  const [homesNames, setHomesNames] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchHomesNames = useCallback(async () => {
    setLoading(true);
    setError(null);

    await handleErrors(
      async () => {
        const response = await getHomesNames();
        setHomesNames(response.data);
      },
      async (err) => {
        console.error('Error obteniendo los nombres de los hogares:', err);
        setError('Hubo un error al obtener los nombres de los hogares');
        setHomesNames([]);
      },
      () => {
        setLoading(false);
      },
    );
  }, [getHomesNames, handleErrors]);

  return [fetchHomesNames, homesNames, loading, error];
};
