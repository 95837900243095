import { Filter, Xmark } from 'iconoir-react';

import React, { useState } from 'react';

import { DropMenu } from '../../atoms/DropMenu';
import Dropdown from '../../atoms/Dropdown';
import { Icon } from '../../atoms/Icon';
import { LinkOrButton } from '../../atoms/LinkOrButton';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { IFilterProps } from './types';

const FilterComponent = ({
  blocksList,
  residencesList,
  onFiltersChange,
  groupingType,
}: IFilterProps) => {
  const [filters, setFilters] = useState<{
    [key: string]: { label: string; value: string }[];
  }>({});
  const [, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [newFilterValue, setNewFilterValue] = useState<string>('');
  const [isDropMenuOpen, setIsDropMenuOpen] = useState(false);

  const filterOptions = [
    { label: 'Residencia', value: 'apartamento' },
    groupingType.toString() !== 'NINGUNA'
      ? {
          label: groupingType
            .toString()
            .toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase()),
          value: 'torre',
        }
      : null,
    { label: 'Todos', value: 'todos' },
  ].filter(
    (option): option is { label: string; value: string } => option !== null,
  );

  const handleAddFilter = () => {
    setIsDropMenuOpen(!isDropMenuOpen);
  };

  const handleSelectFilter = (label: string) => {
    setSelectedOption(label);
    setNewFilterValue('');
    setIsDropdownOpen(false);
    setIsDropMenuOpen(false);
    if (label === 'todos') {
      const newFilters = { todos: [{ label: 'Todos', value: 'todos' }] };
      setFilters(newFilters);
      onFiltersChange({ todos: ['todos'] });
    } else {
      const newFilters = { ...filters };
      delete newFilters['todos'];
      setFilters(newFilters);
      onFiltersChange(
        Object.fromEntries(
          Object.entries(newFilters).map(([key, items]) => [
            key,
            items.map((item) => item.value),
          ]),
        ),
      );
    }
  };

  const handleRemoveFilter = (key: string, value?: string) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (value) {
        newFilters[key] = newFilters[key].filter(
          (item) => item.value !== value,
        );
        if (newFilters[key].length === 0) {
          delete newFilters[key];
        }
      } else {
        delete newFilters[key];
      }
      onFiltersChange(
        Object.fromEntries(
          Object.entries(newFilters).map(([key, items]) => [
            key,
            items.map((item) => item.value),
          ]),
        ),
      );
      return newFilters;
    });
  };

  const getDropdownOptions = () => {
    if (selectedOption === 'torre') {
      return blocksList;
    }
    if (selectedOption === 'apartamento') {
      return residencesList;
    }
    return [];
  };

  const handleNewFilterValueChange = (value: string) => {
    const option = getDropdownOptions().find(
      (option) => option.value === value,
    );
    const label = option ? option.label : value;
    if (selectedOption) {
      setFilters((prevFilters) => {
        const updatedFilters = {
          ...prevFilters,
          [selectedOption]: [
            ...(prevFilters[selectedOption] || []),
            { label, value },
          ],
        };
        onFiltersChange(
          Object.fromEntries(
            Object.entries(updatedFilters).map(([key, items]) => [
              key,
              items.map((item) => item.value),
            ]),
          ),
        );
        return updatedFilters;
      });
    }
    setSelectedOption(undefined);
    setNewFilterValue('');
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filtersContainer}>
        <Icon color='secondary2' width={17} strokeWidth={1.5}>
          <Filter />
        </Icon>
        {Object.entries(filters).map(([key, values]) =>
          values.map((item, index) => (
            <div
              key={`${key}-${index}`}
              className={`${styles.filterTag} ${styles[key]}`}
            >
              <Text text={item.label} textStyle='B2-Regular' />
              <Icon
                color={key === 'torre' ? 'secondary2' : 'neutral'}
                width={14}
                onClick={() => handleRemoveFilter(key, item.value)}
              >
                <Xmark />
              </Icon>
            </div>
          )),
        )}

        <div className={styles.inputContainer}>
          {selectedOption && selectedOption !== 'todos' && (
            <div className={styles.filterInputContainer}>
              <Dropdown
                options={filterOptions}
                selectedValue={selectedOption}
                onValueChange={handleSelectFilter}
                style={styles.dropdown}
                styleContainer={styles.dropdownContainer}
                iconColor='secondary2'
                filter
              />
              <Dropdown
                options={getDropdownOptions()}
                selectedValue={newFilterValue}
                onValueChange={handleNewFilterValueChange}
                style={styles.inputStyle}
                searchEnabled
              />
            </div>
          )}
        </div>
        <div className={styles.newFilter}>
          {!filters['todos'] && (
            <div className={styles.addButtonContainer}>
              <LinkOrButton
                text='+ Filtro'
                textStyle='B1-Regular'
                color='secondary2'
                onClick={handleAddFilter}
              />
            </div>
          )}
          {isDropMenuOpen && (
            <DropMenu
              items={filterOptions.map((option) => ({
                label: option.label,
                onClick: () => handleSelectFilter(option.value),
              }))}
              isOpen={isDropMenuOpen}
              customClass={styles.dropMenu}
              textStyles='B2-Regular'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { FilterComponent };
