import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useHandleErrors } from '../../../../utils/errors';

import { AddNotificationForResidencesDTO } from '../dto/AddNotificationForResidences.dto';
import { useNotificationService } from '../service/Notification.service';
import { IUseAddResidencesNotification } from './types';

export const useAddResidencesNotification =
  (): IUseAddResidencesNotification => {
    const { addResidencesNotification } = useNotificationService();
    const [handleErrors] = useHandleErrors();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successCreation, setSuccessCreation] = useState(false);

    const handleNotifyResidences = useCallback(
      async (data: AddNotificationForResidencesDTO) => {
        setLoading(true);
        setError(null);
        setSuccessCreation(false);

        await handleErrors(
          async () => {
            await addResidencesNotification(data);
            setSuccessCreation(true);
          },
          async (err) => {
            console.error(
              'Error creando la notificación para las residencias:',
              err,
            );
            setError(
              'Hubo un error al crear la notificación para las residencias',
            );
            setSuccessCreation(false);
          },
          () => {
            setLoading(false);
          },
        );
      },
      [addResidencesNotification, handleErrors],
    );

    useEffect(() => {
      if (error) {
        toast.error(error);
        setError(null);
      }
    }, [error]);

    return [handleNotifyResidences, loading, successCreation, error];
  };
