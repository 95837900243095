import React from 'react';
import { Link } from 'react-router-dom';

import Text from '../Text';
import styles from './styles.module.scss';
import { ILinkOrButtonProps } from './types';

const LinkOrButton: React.FC<ILinkOrButtonProps> = ({
  href,
  onClick,
  className,
  text,
  textStyle,
  color,
  filter,
  active,
}) => {
  return href ? (
    <Link to={href} className={className}>
      <Text text={text} textStyle={textStyle} color={color} />
    </Link>
  ) : (
    <a
      onClick={onClick}
      className={`${className} ${filter ? styles.filter : ''} ${active ? styles.active : ''}`}
    >
      <Text text={text} textStyle={textStyle} color={color} />
    </a>
  );
};

export { LinkOrButton };
