import { Formik } from 'formik';
import { Key, User } from 'iconoir-react';
import * as Yup from 'yup';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../feature/ui/atoms/Button';
import { Input } from '../../../feature/ui/atoms/Input';
import { Loading } from '../../../feature/ui/atoms/Loading';
import Text from '../../../feature/ui/atoms/Text';
import { Icon } from '../../ui/atoms/Icon';

import { useHandleErrors } from '../../../utils/errors';

import { AuthenticationService } from '../../../feature/authentication/core/service/Authentication.service';
import styles from './styles.module.scss';
import { IInitialValuesForm } from './types';

const LoginForm = () => {
  const authService = new AuthenticationService();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [handleErrors] = useHandleErrors();

  const initialValues: IInitialValuesForm = {
    email: '',
    password: '',
  };

  const signInSchema = Yup.object().shape({
    email: Yup.string()
      .email('Correo electrónico inválido')
      .required('Este campo es requerido'),
    password: Yup.string().required('Este campo es requerido'),
  });

  const onSubmit = async (values: IInitialValuesForm) => {
    setIsLoading(true);
    setErrorMessage('');
    await handleErrors(
      async () => {
        await authService.signIn({
          email: values.email,
          password: values.password,
        });
      },
      async () => setErrorMessage('Correo y/o contraseña incorrectos.'),
      undefined,
      setIsLoading,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={signInSchema}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
        <form className={styles.container} onSubmit={handleSubmit}>
          <div className={styles.formContainer}>
            <Input
              onChangeText={handleChange('email')}
              label={'Correo electrónico'}
              icon={
                <Icon color='black' strokeWidth={1.5}>
                  <User />
                </Icon>
              }
              autoCapitalize={'none'}
              placeholder={'Correo electrónico'}
              onBlur={handleBlur('email')}
              value={values.email}
              error={errors.email}
              style={styles.input}
              loginInput={true}
            />
            <Input
              autoCapitalize={'none'}
              onChangeText={handleChange('password')}
              label={'Contraseña'}
              icon={
                <Icon color='black' strokeWidth={1.5}>
                  <Key />
                </Icon>
              }
              placeholder='*****************'
              onBlur={handleBlur('password')}
              value={values.password}
              secureTextEntry
              error={errors.password}
              style={styles.input}
            />
            {errorMessage && (
              <div className={styles.errorMessage}>
                <Text text={errorMessage} textStyle='B3-Bold' color='primary' />
              </div>
            )}
            <div className={styles.containerButton}>
              {isLoading ? (
                <div className={styles.loaderContainer}>
                  <Loading size='small' backgroundColor={false} />
                </div>
              ) : (
                <Button text='Iniciar sesión' onPress={handleSubmit} />
              )}
            </div>
          </div>
          <Link className={styles.forgotPassword} to={'/forgot'}>
            <Text
              text={'¿Olvidaste la contraseña?'}
              textStyle='B3-Regular'
              color='primary'
            />
          </Link>
        </form>
      )}
    </Formik>
  );
};

export { LoginForm };
